import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import api from '../../../shared/api';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';

import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import FormDialog from '../../../components/FormDialog/FormDialog';
import BoardTop from '../../../components/User/Board/BoardTop';

import { PieChart, Pie, Sector, Cell } from 'recharts';

import '../../../css/ProductRunner.scss';
import './styles.scss';

class ExamplePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingMessage: 'Loading the Example page. Just a sec...',
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle: "", // this is what the user sees (could be blank)
            title: 'This is my Example Page',
        }
    }

    componentWillMount() {
        this.loadContent();
    }

    componentDidMount() {

    }

    loadContent = async () => {
        setTimeout(() => {

            this.setState({ loading: false });
        }
            , 3000);
    };

    componentWillReceiveProps(nextProps) {
    }

    // *******************************************
    //
    //    for dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    closeSignIn = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = (formName, response) => {
        this.setState({ formDialogOpen: false });
    }

    renderChartTest() {
 
        const data = [{ name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 }];
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };

        return (
            <PieChart width={800} height={400} onMouseEnter={this.onPieEnter}>
                <Pie
                    data={data}
                    cx={300}
                    cy={200}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                >
                    {
                        data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
            </PieChart>
        );
    }


    render() {
        const subtitle = 'my subtitle';
        const divHeight = '100px';
        return (
            <Fragment>
                <div className="navbar-margin">
                    {this.state.loading ? (
                        <LoadingIndicator message={this.state.loadingMessage}/>
                    ) : (
                            <Fragment>
                                <div class='examplepage-title'>
                                    {this.state.title}
                                </div>
                                <div style={{ padding: 100 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => this.openDialog(`SampleForm`, `Test TypeForm in a Dialog`)}
                                    >
                                        Dialog Form Test
                                </Button>
                                <div>
                                    {this.renderChartTest()}
                                </div>
                                </div>
                                <div style={{marginTop: 100, width: 400, height: divHeight, padding: 12, background: '#ccd'}}>
                                <BoardTop
                                                title={`My Board`}
                                                subTitle={`For page: ${this.state.title}`}
                                                index={2}
                                                // onAdd={() => this.addNewProduct(index)}
                                                // menu={['Open', 'Edit', 'Delete']}
                                                menu={[]}
                                            />
                                </div>

                            </Fragment>
                        )}

                </div>
                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    onClose={this.formClosed}
                />
            </Fragment>
        );
    }
}

export default ExamplePage;
