import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// card
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Button } from '@material-ui/core'

import UserAvatar from '../../../components/User/UserAvatar/UserAvatar';
import Moment from 'react-moment';
import Divider from '@material-ui/core/Divider';

import Vote from './Vote';
import TextBox from './TextBox';
import Option from './Option';
import Assign from './Assign';
import 'font-awesome/css/font-awesome.min.css';
import api from '../../../shared/api';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import reactStringReplace from 'react-string-replace';
import Link from '@material-ui/core/Link';

import Profile from './Profile';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import ScrollToTop from 'react-scroll-up';
import Img from 'react-image';
const scrollTop = require('../../../img/pr/images/scrollTop.png');

const styles = theme => ({
  card: {
    marginTop: '10px;',
    borderRadius: '20px',
    border: '2px solid'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  replybgColor: {
    backgroundColor: '#F5F5F5'
  },
  bgColor: {
    backgroundColor: '#cccccc'
  },
  statusIcon: {
    marginLeft: 'auto',
  },
  fab: {
    margin: theme.spacing.unit * 2,
  },
  filterCard: {
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class Note extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseNoteId: false,
      editNoteId: false,
      showReplyMessage: false,
      userId: JSON.parse(sessionStorage.getItem("pr_user")).id,
      notes: [],
      userDetails: [],
      openConfirm: false,
      expanded: null,
    }
  }
  showReplies = (noteId) => {
    this.setState(state => ({ collapseNoteId: this.state.collapseNoteId == noteId ? (false) : noteId }));
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.filter) { // checks for filter menu is clicked or not 
      this.filter(nextProps.onFilter);
    }
    if (nextProps.newNote.length == 1) {
      let currentNotes = this.state.notes;
      currentNotes = [nextProps.newNote[0], ...currentNotes];
      
      if (nextProps.onFilter[0]['key'] == 2 || nextProps.onFilter[0]['key'] == 1 ) {
        this.setState({ notes: currentNotes, openConfirm: true });
      } else {
        if (this.state.notes != []) {
          this.setState({ notes: this.state.notes, openConfirm: true });
        }
      }
    }
  }
  componentWillMount() {
    this.filter(this.props.onFilter);
  }

  handleCloseSnack = () => {
    this.setState({ openConfirm: false });
  };

  TransitionRight(props) {
    return <Slide {...props} direction="left" />;
  }

  editMessage = (noteId) => {
    this.setState(state => ({ editNoteId: this.state.editNoteId == noteId ? (false) : noteId }));
  }
  reply = () => {
    this.setState(state => ({ showReplyMessage: true }));
  }

  noteStatus = (value) => {
    switch (value) {
      case 1:
        return (null);
      case 2:
        return (
          <Typography> <Tooltip title="Suggestion"><i className="fa fa-lightbulb fa-2x" style={{ color: '#4d4dff' }}></i></Tooltip></Typography>
          // <svg xmlns="http://www.w3.org/2000/svg" color="danger" width="24" height="24" viewBox="0 0 24 24"><path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" /></svg>
        );
      case 3:
        return (
          <Typography> <Tooltip title="Requirement"><i className="fa fa-exclamation-triangle fa-2x" style={{ color: '#ff4d4d' }}></i></Tooltip></Typography>
        );
      case 4:
        return (
          <Typography> <Tooltip title="Approval"><i className="far fa-square fa-2x" style={{ color: '#ffff4d' }}></i></Tooltip></Typography>
        );
      case 5:
        return (
          <Typography> <Tooltip title="Decision"><i className="fa fa-question-circle fa-2x" style={{ color: '#d2a679' }}></i></Tooltip></Typography>
          // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>
        );
      case 6:
        return (
          <Typography> <Tooltip title="Approved"><i className="fa fa-check-square fa-2x" color="primary" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
        );
      case 7:
        return (
          <Typography> <Tooltip title="Decided"><i className="fa fa-check-square fa-2x" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
        );
      case 8:
        return (
          <Typography> <Tooltip title="Resolved"><i className="fa fa-check-square fa-2x" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
        );
      case 9:
        return (
          <Typography> <Tooltip title="Not Approved"><i className="fa fa-times-circle fa-2x" style={{ color: '#ff4d4d' }}></i></Tooltip></Typography>
        );
      default:
        return (null);
    }
  }

  //  create or edit a reply 
  send = async (noteId, response, isEditing, parentNoteId) => {

    if (isEditing)
      // Editing a note
      try {
        const note = await api.patch('/note/edit/note ', {
          noteId: noteId,
          note: response.note,
          author_id: JSON.parse(sessionStorage.getItem("pr_user")).id
        });
        if (note.data.data.success) {
          let currentNotes = this.state.notes;
          // updating the current notes with updated child notes.
          if (noteId == parentNoteId) {//check for the parent note else update the parent's child note
            currentNotes.find(x => x._id === noteId).note = note.data.data.data.note;
            currentNotes.find(x => x._id === noteId).mentions = note.data.data.data.mentions;
          } else {
            currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).note = note.data.data.data.note;
            currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).mentions = note.data.data.data.mentions;
          }    // updating the state.
          this.setState({ notes: currentNotes });
        } else {
          this.setState({ errorMessage: note.data.data.errMsg });
        }
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }
    else
      // For creating new reply
      // console.log(response);
      try {
        const note = await api.post('/note/create/reply', {
          noteId: noteId,
          note: response.note,
          author_id: JSON.parse(sessionStorage.getItem("pr_user")).id
        });
        if (note.data.data.success) {
          let currentNotes = this.state.notes;
          // updating the current notes with updated child notes.
          currentNotes.find(x => x._id === noteId).childNotes = [...currentNotes.find(x => x._id === noteId).childNotes, note.data.data.data];
          // updating the state.
          this.setState({ notes: currentNotes });
          if (!this.state.collapseNoteId) {
            this.setState(state => ({ collapseNoteId: this.state.collapseNoteId == noteId ? (false) : noteId }));
          }
        } else {
          this.setState({ errorMessage: note.data.data.errMsg });
        }
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }

  }
  //  vote
  vote = async (noteId, type, parentNoteId) => {
    try {
      const note = await api.post('/note/vote/type', {
        noteId: noteId,
        type: type,
        voterId: JSON.parse(sessionStorage.getItem("pr_user")).id
      });
      if (note.data.data.success) {
        let currentNotes = this.state.notes;
        if (!note.data.data.isRemoved) { //checking whether the note was added
          if (note.data.data.isNew) {
            if (noteId == parentNoteId) {
              currentNotes.find(x => x._id === noteId).votes = [...currentNotes.find(x => x._id === noteId).votes, note.data.data.data];
            } else {
              currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).votes = [...currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).votes, note.data.data.data];
            }
          } else {
            if (noteId == parentNoteId) {
              currentNotes.find(x => x._id === noteId).votes.find(x => x.actionType === type).voter = [...currentNotes.find(x => x._id === noteId).votes.find(x => x.actionType === type).voter, note.data.data.data];
            } else {
              currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).votes.find(x => x.actionType === type).voter = [...currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).votes.find(x => x.actionType === type).voter, note.data.data.data];
            }
          }
          // updating the current notes with updated votes notes.

        } else {
          // Remove the vote object from the state (note)
          if (noteId == parentNoteId) {
            currentNotes.find(x => x._id === noteId).votes.find(x => x.actionType == type).voter = currentNotes.find(x => x._id === noteId).votes.find(x => x.actionType == type).voter.filter(vote => vote.user._id != JSON.parse(sessionStorage.getItem("pr_user")).id);
          } else {
            currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).votes.find(x => x.actionType == type).voter = currentNotes.find(x => x._id === parentNoteId).childNotes.find(x => x._id === noteId).votes.find(x => x.actionType == type).voter.filter(vote => vote.user._id != JSON.parse(sessionStorage.getItem("pr_user")).id);
          }
        }
        // updating the state.
        this.setState({ notes: currentNotes });

      } else {
        this.setState({ errorMessage: note.data.data.errMsg });
      }
    } catch (e) {
      // do this if api.get() throws an error;
      alert(e);
    }
  }
  // Menu action
  action = async (noteId, parentNoteId, actionId, category) => {
    // category 1 refers to field name "status" in the notes document approved(6), decided(7), don't approve(9)
    // category 2 refers to field name "type" in the notes document archive(2) delete(0)
    let userId = JSON.parse(sessionStorage.getItem("pr_user")).id;
    if (actionId == 0 && category == 1)
      try {
        const note = await api.patch('note/action/status/' + noteId + '/' + actionId);
        if (note.data.data.success) {
          let currentNotes = this.state.notes;
          if (actionId == 0) //Checking if the type is delete action 
            if (noteId == parentNoteId) {//checking for parent note for 
              currentNotes = currentNotes.filter(x => x._id != noteId);
              
              //check the locationType notes count empty or not, if empty push locationType to array to change bubble color
              if(this.props.locationType != 'noLocation'){
                let resArr = [];
                api.get('/note/get/locationCount/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType).then((result) => {
                  if(result.data.data.data.count == 0) {
                    resArr.push({
                        locationType : this.props.locationType,
                        isAdd : false,
                        isDelete : true
                    });
                    this.storeLocationsArr(resArr);
                }
                })  
              }
            } else {// child notes 
              currentNotes.find(x => x._id === parentNoteId).childNotes = currentNotes.find(x => x._id === parentNoteId).childNotes.filter(x => x._id != noteId)
            }
          //updating the state
          this.setState({ notes: currentNotes });
        } else {
          this.setState({ errorMessage: note.data.data.errMsg });
        }
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }
    else if (actionId == 2 && category == 1) {
      try {
        const note = await api.patch('note/archive/' + noteId + '/' + userId);
        if (note.data.data.success) {
          let currentNotes = this.state.notes;
          // if (noteId == parentNoteId) {//checking for parent note for 
          currentNotes = currentNotes.filter(x => x._id != noteId);
          // }
          //updating the state
          this.setState({ notes: currentNotes });
        } else {
          this.setState({ errorMessage: note.data.data.errMsg });
        }
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }
    }
    else if (actionId == 3 && category == 1) {
      try {
        const note = await api.patch('note/unarchive/' + noteId + '/' + userId);
        if (note.data.data.success) {
          let currentNotes = this.state.notes;
          currentNotes.find(x => x._id == noteId).archive = [];
          currentNotes = currentNotes.filter(x => x.archive.length != 0);
          //updating the state
          this.setState({ notes: currentNotes });
        } else {
          this.setState({ errorMessage: note.data.data.errMsg });
        }
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }
    }
    else
      try {
        const note = await api.patch('note/action/type/' + noteId + '/' + userId + '/' + actionId);
        if (note.data.data.success) {
          let currentNotes = this.state.notes;
          currentNotes.find(x => x._id === noteId).type = actionId;
          this.setState({ notes: currentNotes });
        } else {
          this.setState({ errorMessage: note.data.data.errMsg });
        }
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }

  }
  // To reassign 
  assign = async (noteId, assigneeId) => {
    try {
      const note = await api.post('/note/reassign', {
        noteId: noteId,
        assignedTo: assigneeId,
        assignedBy: JSON.parse(sessionStorage.getItem("pr_user")).id
      });
      if (note.data.data.success) {
        let currentNotes = this.state.notes;
        // updating the current notes with updated child notes.
        currentNotes.find(x => x._id === noteId).assigned = [];
        currentNotes.find(x => x._id === noteId).assigned = [...currentNotes.find(x => x._id === noteId).assigned, note.data.data.data];
        // updating the state.
        this.setState({ notes: currentNotes });
      } else {
        this.setState({ errorMessage: note.data.data.errMsg });
      }
    } catch (e) {
      // do this if api.get() throws an error;
      alert(e);
    }
  }

  filter = async (filterSelected) => {
    let newFilter = filterSelected.filter(x => x.value == true);
    let notes = [];
    let locationType = 'noLocation';
    if (newFilter.length == 1) {
      switch (newFilter[0].key) {
        case 1:
          // All notes
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/all/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            }
            else {
              notes = await api.get('/note/all/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
        case 3:
          // Archive notes
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/filter/archive/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            }
            else {
              notes = await api.get('/note/filter/archive/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
        case 4:
          // Assigned to me
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/filter/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            }
            else {
              notes = await api.get('/note/filter/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }

            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;

        default:
          // Active notes
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/filter/active/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            } else {
              notes = await api.get('/note/filter/active/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            //
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
      }
    } else if (newFilter.length == 2) {
      switch (true) {
        case (newFilter[0].key == 2 && newFilter[1].key == 4):
          // Active notes and assigned to me
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/filter/active/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            } else {
              notes = await api.get('/note/filter/active/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
        case (newFilter[0].key == 3 && newFilter[1].key == 4):
          //Archive and Assigned to me.
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/filter/archive/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            } else {
              notes = await api.get('/note/filter/archive/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
        default:
          // all notes
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/all/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            } else {
              notes = await api.get('/note/all/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
      }

    } else if (newFilter.length == 3) {
      switch (newFilter[0].key && newFilter[1].key && newFilter[2].key) {
        case (2 && 3 && 4):
          // Assigned to me
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/filter/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            } else {
              notes = await api.get('/note/filter/assign-to-me/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;

        default:
          // all notes
          try {
            if (!this.props.locationType) {
              notes = await api.get('/note/all/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
            } else {
              notes = await api.get('/note/all/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
            }
            this.updateNote(notes);
          } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
          }
          break;
      }

    }
    else {
      // Active notes
      try {
        if (!this.props.locationType) {
          notes = await api.get('/note/filter/active/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + locationType);
        } else {
          notes = await api.get('/note/filter/active/' + JSON.parse(sessionStorage.getItem("pr_user")).id + '/' + this.props.productId + '/' + this.props.taskId + '/' + this.props.locationType);
        }
        this.updateNote(notes);
      } catch (e) {
        // do this if api.get() throws an error;
        alert(e);
      }
    }
  }

  storeLocationsArr = (locationsArr) => {
    //console.log(locationsArr);
    if (this.props.locationType != undefined) {
      this.props.storeLocationsValue(locationsArr);
    }
  }

  updateNote(notes) {
    this.setState(
      {
        notes: notes.data.data.data,
      },
      () => {
        // do this when setState() is finished...

      }
    );
  }
  renderTheString(note) {
    if (note.mentions.length > 0) { //checking for mentions
      //console.log(note.mentions);
      //To remove the user Id from note content
      let a = note.note.replace(/##[a-z0-9]+/ig, "");
      // To get the mention(user) ID
      // let userId = note.note.match(/##[a-z0-9]+/ig)[0].replace();
      // To remove the special character from note content expect for @ character
      let b = a.replace(/[^a-zA-Z0-9@. ]/g, "");
      b = b.replace('/[\n]/g',' ');
      let replacedText;
      // Match @-mentions
      replacedText = reactStringReplace(b, /@([\w-\.]+@[\w-\.]+\.\w+)/g, (match, i) => (
        <Link style={{ color: '#f50057' }} component="button" key={match + i} ><Profile name={"@" + match} username={match} isMention={true} /></Link>
      ));

      return replacedText;
    } else
      return note.note;
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const vertical = 'top'; // for snackbar
    const horizontal = 'right' // for snackbar
    const { classes, users, onFilter } = this.props;
    const mentionDataFormat = users.map(({ _id, username }) => ({ id: _id, display: username })); // Formatting according to mentions plugin data format
    const { notes, userId } = this.state;
    const { expanded } = this.state;
    const calendarStrings = {
      lastDay: 'LT [Yesterday] ',
      sameDay: 'LT [Today]',
      nextDay: 'LT [Tomorrow]',
      lastWeek: '[last] dddd [at] LT',
      nextWeek: 'dddd [at] LT',
      sameElse: 'L'
    };
    const reactionIconList = [
      { icon: '<ThumpUpIcon />', value: 1 },
      { icon: '<ThumpDownIcon />', value: 0 },
      // { icon : 'face', value : 2 }
    ]
    let renderNote = null;
    if (notes.length == 0) {
      renderNote = (
        <div className={`center`}>

          <Button
            onClick={this.props.addNote} variant="outlined" color="primary"
          >Add a Note</Button>

        </div>
      )
    } else {
      renderNote = (
        <div>
          <List component="nav" className={classes.filterCard}>
            <ListItem>
              <ListItemText primary={"Showing : " + onFilter.filter(x => x.value == true).map(fi => {
                return " " + fi.label
              })} />
            </ListItem>
          </List>
          {notes.map((note, i) => {

            return <Card key={i} className={classes.card}>
              <CardHeader
                avatar={
                  <UserAvatar username={note.author.firstName} size={'sm'} />
                }
                action={
                  <Option key={i} note={note} authorId={note.author._id} type={this.action} noteId={note._id} parentNote={true} parentNoteId={note._id} edit={this.editMessage} showDelete={note.childNotes.length > 0 ? (false) : true} />
                }
                // title={note.author.firstName}
                title={<Profile name={note.author.firstName} username={note.author.username} isMention={false} />}
                subheader={<Moment calendar={calendarStrings} date={note.creationDate} />}
              />

              <CardContent className={classes.cardContentBorder}>
                <CardHeader
                  action={this.noteStatus(note.type)}
                  subheader={note.subject}
                />
                {this.state.editNoteId == note._id ? (<TextBox note={note} noteId={note._id} parentNoteId={note._id} key={i} send={this.send} messageData={note.note} isEditing={true} cancel={this.editMessage} users={mentionDataFormat} />) : <Typography component="p">{this.renderTheString(note)}</Typography>}
              </CardContent>
              {/* Main note */}
              {/* card actions */}
              <CardActions className={classes.actions}>
                {/* Voting component */}
                <Vote noteId={note._id} parentNoteId={note._id} key={i} vote={this.vote} note={note} />
                {/* Voting component */}
                {note.childNotes.length > 0 ? (
                  <Badge style={{ right: 10 }} className={classnames(classes.expand)} badgeContent={note.childNotes.length} color="secondary">
                    <IconButton
                      className={classnames(classes.expand, {
                        [classes.expandOpen]: this.state.collapseNoteId,
                      })}
                      onClick={() => this.showReplies(note._id)}
                      aria-expanded={this.state.collapseNoteId == note._id ? (true) : false}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Badge>
                ) : null}
              </CardActions>
              {/* card actions */}
              {/* reply list */}
              {note.childNotes.length > 0 ? (
                <Collapse in={this.state.collapseNoteId == note._id ? (true) : false} timeout="auto" unmountOnExit>
                  <CardContent className={classes.replybgColor}>
                    {note.childNotes.map((reply, i) => {
                      return <List key={i} component="nav">
                        <CardHeader
                          avatar={
                            <UserAvatar username={reply.author.firstName} size={'sm'} />
                          }
                          action={this.state.userId == reply.author._id ? (<Option note={note} key={i} authorId={reply.author._id} type={this.action} noteId={reply._id} parentNote={false} parentNoteId={note._id} edit={this.editMessage} showDelete={true} />) : null}
                          //title={reply.author.firstName}
                          title={<Profile name={reply.author.firstName} username={reply.author.username} isMention={false} />}
                          subheader={<Moment calendar={calendarStrings} date={reply.creationDate} />}
                        />
                        {this.state.editNoteId == reply._id ? (<Typography className={classes.nested}> <TextBox note={reply} noteId={reply._id} parentNoteId={note._id} key={i} send={this.send} messageData={reply.note} isEditing={true} cancel={this.editMessage} users={mentionDataFormat} /></Typography>) : <Typography className={classes.nested}> {this.renderTheString(reply)}</Typography>}
                        {/* card actions */}
                        <CardActions className={classes.actions}>
                          {/* Voting component */}
                          <Vote noteId={reply._id} parentNoteId={note._id} key={i} vote={this.vote} note={reply} />
                          {/* Voting component */}
                        </CardActions>
                        <Divider className={classes.bgColor} />
                        {/* card actions */}
                      </List>
                    })}
                  </CardContent>
                  {/* reply list */}
                </Collapse>
              ) : null}
              {/* reply textbox */}
              {note.assigned.length > 0 ? (<CardActions className={classes.actions} disableActionSpacing>
                {/* Assignee */}
                <Assign note={note} noteId={note._id} users={this.props.users} assign={this.assign} loaduser={this.props.onUser} />
                {/* Assignee */}
              </CardActions>) : null}
              <CardActions className={classes.actions} disableActionSpacing>
                <TextBox note={false} noteId={note._id} parentNoteId={note._id} key={i} send={this.send} messageData="" isEditing={false} users={mentionDataFormat} />
              </CardActions>
              {/* reply textbox */}
            </Card>
          })}
          <ScrollToTop showUnder={160}>
            <Img src={scrollTop} style={{ width: 50, height: 50 }} />
          </ScrollToTop>
          <br/>
          { notes[0]['location'] == 'noLocation' 
          ? <Button variant="contained" color="primary" style={{ right: 20, bottom: -10, position: 'absolute' }} onClick={this.props.addNote} className={classes.button}>Add a Note</Button>
          : null}
          
          <Snackbar
            style={{ marginTop: 50 }}
            key="sentMessage"
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={this.TransitionRight}
            open={this.state.openConfirm}
            autoHideDuration={1500}
            onClose={this.handleCloseSnack}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id="message-id">New Notes can be found in All/ Active</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="primary"
                className="snackX"
                onClick={this.handleCloseSnack}
              >
                X
            </IconButton>
            ]}
          />
        </div>
      )
    }
    return (
      <div> {renderNote} </div>
    );
  }
}

Note.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Note);