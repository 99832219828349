import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import merge from 'lodash.merge';

// *********** Material UI *************
import { Dialog, DialogTitle, DialogActions, DialogContent, FormControlLabel, IconButton } from '@material-ui/core';
import { Button, TextField, Checkbox, FormControl, Select, InputLabel, FormHelperText, Input, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// **********  PR Components ****************
import api from '../../shared/api';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import AppData from '../../shared/AppData';
import { ActionTrackChanges } from 'material-ui/svg-icons';

// mentions
import { MentionsInput, Mention } from 'react-mentions'

import defaultStyle from '../User/Note/defaultStyle'
import defaultMentionStyle from '../User/Note/defaultMentionStyle'


// ************* Test forms *****************
const signInForm = {
    formName: 'SignIn',
    isTypeForm: false,
    typeFormId: "12345",
    mustValidate: true,
    form: [
        { type: 'text', prompt: 'Email', placeholder: 'Your email', required: true },
        { type: 'password', prompt: 'Password', required: true },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Sign In',
        skip: 'DNU'         // DNU = Do Not Use
    }
};

const regForm = {
    formName: 'Register',
    isTypeForm: false,
    mustValidate: true,
    form: [
        { type: 'text', prompt: 'Email', placeholder: 'this is your user name', required: true },
        { type: 'text', prompt: 'First Name', placeholder: '', required: true },
        { type: 'text', prompt: 'Last Name', placeholder: '', required: true },
        { type: 'password', prompt: 'Password', required: true },
        { type: 'password', prompt: 'Confirm Password', required: true },
        { type: 'textarea', fieldName: 'Note', prompt: `Tell us why you're registering` },
        { type: 'singlecheck', fieldName: 'Terms', label: `I agree to the terms and conditions`, required: true },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Register',
        skip: 'DNU'         // DNU = Do Not Use
    }
};

const noteForm = {
    formName: 'Note',
    isTypeForm: false,
    mustValidate: true,
    form: [
        { type: 'text', prompt: 'Subject', placeholder: 'subject', required: false },
        { type: 'mention-textarea',fieldName:'Note',prompt: 'Note', placeholder: 'Note', required: false },
        {
            type: 'picklist', fieldName: 'Type', prompt: ``,
            list: [
                { item: 'Note', value: 1, hint: '' },
                { item: 'Suggestion', value: 2, hint: '' },
                { item: 'Requirement', value: 3, hint: '' },
                { item: 'Approval', value: 4, hint: '' },
                { item: 'Decision', value: 5, hint: '' }
            ],
            required: true
        },
        { type: 'select', fieldName: 'Assign', prompt: ``, required: false },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Save',
        skip: 'DNU'         // DNU = Do Not Use
    }
};

const sampleForm = {
    formName: 'SampleForm',
    isTypeForm: false,
    form: [
        { type: 'text', prompt: 'Field 1', placeholder: 'Enter...', required: true },
        { type: 'text', prompt: 'Field 2', placeholder: '(optional)', required: false },
        { type: 'text', prompt: 'Field 3', placeholder: 'Required', required: true },
        { type: 'text', prompt: 'Field 4', placeholder: '', required: true },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Save',
        skip: 'Skip'         // DNU = Do Not Use
    }
};

// ******************* FormDialog Component ******************

class FormDialog extends Component {
    constructor(props) {
        super(props);
        const responses = (props.formData) ? props.formData : {};
        const iAmOpen = (props.isOpen) ? props.isOpen : false;
        const ft = (props.form) ? props.form : null;
        const fn = (props.form) ? props.form : '';
        const isDialog = (props.isDialog === false) ? props.isDialog : true;

        this.state = {
            iAmOpen,
            isOpen: iAmOpen,
            loading: false,
            form: props.form,
            isDialog,
            formName: fn,
            formTemplate: ft,
            firstTime: true,
            errorMessage: '',
            hasData: false,
            responses,
            isWizard: props.isWizard,
            currentQuestion: 0,
            formXPos: 0,
            height: props.height ? props.height: 800,
            userId: sessionStorage.getItem("pr_user") == null ? (null) : JSON.parse(sessionStorage.getItem("pr_user")).id,
            updated: false,
            task: props.task

        };
        this.onChangeText = this.onChangeText.bind(this);
        this.required = {};

        this.forwardKeys = [13, 9, 39, 40, 34];
        this.backKeys = [37, 33, 38];
        this.formWidth = 420;
    }

    static cancelButton = 'Cancel';
    static skipButton = 'Skip';
    static OKButton = 'OK';
    static totalQuestions = 0;


    componentWillMount() {
    }

    componentDidMount() {
    }

    loadFormFromDB = async (formName) => {
        let formTemplate = null;        // set to null to 

        let firstTime = false;
        const userId = AppData.get('userId');

        try {
            const template = await api.get(`/form/getByName/${formName}`);
            this.setState(
                {
                    required: {},   // gotta clear this out when we load a new form
                    formTemplate: template.data.data.form,
                    loading: false
                },
                () => {
                    // do this when setState() is finished...
                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
            formTemplate = sampleForm;
            this.setState({ formTemplate: sampleForm, loading: false });
        }

        return (formTemplate);

        // indicate loading...

        // need to look first to see if the FormUserData collection has this form from the current user
        // normally would attempt to load from DB:
        /*
            formTemplate = code to load from FormUserData with formName and current userId
        */
        /*
         const hasFormAlready = formTemplate !== null;
         if (hasFormAlready) {
             if (formTemplate.isTypeForm) {
                 return (this.renderTypeForm(formTemplate, true));
             } else {
                 return (this.renderFormFromTemplate(formTemplate, true));
             }
         } else {
             // didn't find this form used yet by this user, grab the form from the FormTemplates colletion and start a new form
             // and then save it in the FormUserData collection
             
             // **  formTemplate = code to load from FormTemplate collection with formName and current userId
 
             if (formTemplate) {
                 if (formTemplate.isTypeForm) {
                     return (this.renderTypeForm(formTemplate, false));
                 } else {
                     return (this.renderFormFromTemplate(formTemplate, false));
                 }
             }
             
         }
         */
    }


    componentWillReceiveProps(nextProps) {
        if( !this.state.isDialog ) {
            return;
        }
        const { form, formName, isDialog } = nextProps;
        let { isOpen } = nextProps;
        let formTemplate = null;
        let hasData = false;
        let loading = false;
        let responses = {};

        if (form) {
            // then we have a form already... just put it into the state...
            formTemplate = form.formTemplate;
        } else if ((isOpen && this.state.iAmOpen === false && formName !== '')
            || (this.state.iAmOpen && formName !== this.state.formName)) {
            // need to load the form now.

            switch (formName) {

                // looking for pre-defined forms...
                case 'SignIn':
                    formTemplate = signInForm;
                    break;
                case 'Register':
                    formTemplate = regForm;
                    break;
                /*
            case 'NewProduct':
                formTemplate = newProductForm;
                break;
                */
                case 'Note':
                    formTemplate = noteForm;
                    break;
                // not pre-defined, get the form from the FormTemplates/FormUserData Collections
                // NOTE: this will FIRST look in the FormUserData to see if this form has been completed by this user
                //       if it hasn't, then it will grab the form from the FormTemplates collection to allow this user to start this form
                default:
                    formTemplate = this.loadFormFromDB(formName);
                    loading = true;

                // actually load from the DB here.
            }
        } else if (isOpen && this.state.iAmOpen === true && this.state.formTemplate !== null) {
            formTemplate = this.state.formTemplate;
        }
        this.setState({
            form,
            responses,
         //   isDialog,
            formName,
            formTemplate,
            hasData,
            iAmOpen: isOpen,
            errorMessage: nextProps.errorMessage,
            loading,
            currentQuestion: 0,
            formXPos: 0,
            isWizard: formTemplate ? formTemplate.isWizard === true : false,
            task: nextProps.task
        });

    }

    // *******************************************
    //
    //    form dialog 
    //
    // *******************************************
    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    openMe = () => {
        this.setState({ iAmOpen: true });
    };

    onChangeText = (e) => {
        const id = e.target.id;
        const text = e.target.value;
        let value = {};
        value[id] = text;
        const newState = merge({}, this.state.responses, value);
        this.setState({ responses: newState, updated: true });
    };

    onHandleCheckbox = (e) => {
        const fieldName = e.target.id;
        const newState = merge({}, this.state.responses, { [fieldName]: e.target.checked });
        this.setState({ responses: newState, updated: true });
    };

    onHandlePickList = (event, fieldName) => {
        const newState = merge({}, this.state.responses, { [fieldName]: event.target.value });
        this.setState({ responses: newState, updated: true });
    }
    onHandleSelect = (event, fieldName) => {
        const newState = merge({}, this.state.responses, { [fieldName]: event.target.value });
        this.setState({ responses: newState, updated: true });
    }
    onHandletextareaWithMentions = (event, fieldName) => {
        const newState = merge({}, this.state.responses, { [fieldName]: event.target.value });
        this.setState({ responses: newState });
    }

    closeMe = (button) => {
        // button is one of: 'Cancel', 'Skip', 'OK'

        let valid = true;
        if (button === 'OK' && this.state.formTemplate.mustValidate === true) {
            // then need to validate the form.
            for (const key of Object.keys(this.required)) {

                const value = this.state.responses[key] ? this.state.responses[key] : null;
                if (!value || value === '' || value === null) {
                    valid = false;
                }
            }

            if (!valid) {
                this.setState({ errorMessage: `Sorry, please complete this form's required fields` });
                return;
            }
        }
        if (button === 'SaveAndContinue'  && !this.state.isDialog) {
            if (this.state.updated) {

            } else {
                alert(`Not updated as you've made no changes.`);
                return;
            }
        }

        let response = { action: 'close', status: button, formData: this.state.responses, task: this.state.task };
        if (this.props.onClose) {
            this.setState({ loading: true, updated: false });
            this.props.onClose(this.props.formName, response);
        }
    };

    nextQuestion = (amount) => {
        if (this.state.isWizard) {
            let cq = this.state.currentQuestion;
            let xpos = this.state.formXPos;
            if (amount > 0 && cq + 1 < this.totalQuestions) {
                cq++;
            } else if (amount < 0 && cq > 0) {
                cq--;
            }
            xpos = -(cq * this.formWidth);
            this.setState({ currentQuestion: cq, formXPos: xpos });
        }
    };

    onKeyDown = (e, type) => {
        const { keyCode } = e;
        if (this.forwardKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf' || keyCode !== 13) {
                this.nextQuestion(1);
            }
        }
        if (this.backKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf') {
                this.nextQuestion(-1);
            }
        }
        return null;
    }


    // ************************************************
    //
    // render form item logic 
    //
    // ************************************************
    renderFormItem = (item) => {
        const { type, prompt, placeholder, required, fieldName, label, list } = item;
        const field = fieldName ? fieldName : prompt;
        if (required) {
            this.required[field] = type;
        }

        const questionClass = this.state.isWizard ? 'fd-wizardPanel' : 'fd-notWizardQuestion';
        const pickClass = this.state.isWizard ? 'fd-wizardPanel' : 'fd-notWizardSelect';
        
        switch (type) {
            case 'row':
                return (
                    <div style={{ height: 40, background: 'gray' }}>
                        {prompt}
                    </div>
                );

            case 'password':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onKeyDown={this.onKeyDown}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            fullWidth
                        />
                    </div>
                );
            case 'text':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onKeyDown={(event) => this.onKeyDown(event, 'tf')}
                            placeholder={placeholder}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        />
                    </div>
                );
            case 'textarea':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            placeholder={placeholder}
                            margin="normal"
                            // variant="outlined"
                            multiline
                            rowsMax="4"
                            fullWidth
                            variant="outlined"
                        />
                    </div>
                )

            case 'singlecheck':
                return (
                    <div className={questionClass}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={fieldName}
                                    required={required}
                                    onChange={this.onHandleCheckbox}
                                    value={this.state.responses[fieldName]}
                                />
                            }
                            label={label}
                        />
                    </div>
                )
            case 'picklist':
                return (
                    <div className={pickClass}>
                        <FormControl className={pickClass} >

                            <Select style={{ marginTop: 20 }}
                                id={fieldName}
                                name={fieldName}
                                value={this.state.responses[fieldName]}
                                onChange={(event) => this.onHandlePickList(event, fieldName)}
                                displayEmpty
                            // className={'fd-selectEmpty'}
                            >
                                <MenuItem value="">
                                    <em>Please choose...</em>
                                </MenuItem>
                                {list.map((item) => (

                                    <MenuItem value={item.value}>
                                        {item.item}

                                        {/*}
                                    <div value={item.value}>
                                        <div style={{ color: 'black' }} value={item.value}>{item.item}</div>
                                        {item.hint ? <div style={{ padding: '0px 8px', color: '#ccc', fontSize: 12, fontStyle: 'italic' }}>{item.hint}</div> : null}
                                    </div>
                                */}
                                    </MenuItem>

                                ))}
                            </Select>
                            <FormHelperText>{prompt}</FormHelperText>
                        </FormControl>
                    </div>
                )
            case 'select':
                return (<div className={pickClass}>
                    <FormControl className={pickClass} >

                        <Select style={{ marginTop: 20 }}
                            id={fieldName}
                            name={fieldName}
                            value={this.state.responses[fieldName]}
                            onChange={(event) => this.onHandleSelect(event, fieldName)}
                        >
                            <MenuItem value="">
                                <em>{fieldName}</em>
                            </MenuItem>
                            {this.props.users.map((u) => (
                                <div value={u._id}>
                                    <div style={{ color: 'black' }} value={u._id}>{u.fullName}</div>
                                </div>
                            ))}
                        </Select>
                        <FormHelperText>{fieldName}</FormHelperText>
                    </FormControl>
                </div>)
             case 'mention-textarea':
             const mentionDataFormat = this.props.users.map(({ _id, username }) => ({ id: _id, display: username })); // Formatting according to mentions plugin data format
             return (<div className={questionClass}>
                 <FormControl style={{ margin: 0, minWidth: 350 }} >
                     <MentionsInput
                            value={this.state.responses[fieldName]}
                            onChange={(event) => this.onHandletextareaWithMentions(event, fieldName)}
                            style={defaultStyle}
                            markup="@[__display__](##__id__)"
                            placeholder={""}
                            displayTransform={this.mentionDisplayTransform}
                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={mentionDataFormat}
                                renderSuggestion={this.renderUserSuggestion}
                                // onAdd={onAdd}
                                style={defaultMentionStyle}
                            />

                            {/* <Mention
                                type="email"
                                trigger={emailRegex}
                                data={search => [{ id: search, display: search }]}
                                // onAdd={onAdd}
                                style={{ backgroundColor: '#d1c4e9' }}
                            /> */}
                        </MentionsInput>
                     <FormHelperText>{fieldName}</FormHelperText>
                 </FormControl>
             </div>)

            default:
                return (
                    <div style={{ height: 40, background: 'black', color: 'white', textAlign: 'center' }} className={questionClass}>
                        {prompt}
                    </div>
                );
        }
    }

    renderFormFromTemplate = (formTemplate, firstTime) => {
        if (!formTemplate) {
            return (null);
        }
        const { form, buttons } = formTemplate;

        const formContentClass = this.state.isWizard ? 'fd-wizardContent' : null;
        this.totalQuestions = form.length;

        if (buttons) {
            if (buttons.cancel) {
                this.cancelButton = buttons.cancel;
            }
            if (buttons.skip) {
                this.skipButton = buttons.skip;
            }
            if (buttons.ok) {
                this.OKButton = buttons.ok;
            }
        }
        return (
            <div className={formContentClass} style={{ marginLeft: this.state.formXPos, transition: 'all 0.3s ease-in-out 0s' }}>
                {form.map((item) => {
                    return (this.renderFormItem(item))
                }
                )}
            </div>
        )
    }

    renderTypeForm = (formTemplate, firstTime) => {
        // this is where we launch/run the embedded TypeForm etc.

        // done loading it...
    }

    renderForm = () => {
        const { formName } = this.state;
        if (formName === '' || this.state.iAmOpen === false) {
            return (<div>Loading...</div>);
        } else {
            return (this.renderFormFromTemplate(this.state.formTemplate, false));
        }
    }
    // mentions
    renderSuggestion = (id, display, search, highlightedDisplay) => { return (<div className="suggestion-item"> @{highlightedDisplay} </div>); }
    mentionDisplayTransform = (id, display, type) => { return "@" + display; }
    // mentions
    render() {
        return (
            <div>
                {this.state.isDialog ?
                <Dialog
                    open={this.state.iAmOpen}
                    fullWidth={true}
                    maxWidth={'md'}
                    TransitionComponent={this.Transition}
                    keepMounted
                    onClose={() => this.closeMe(`Cancel`)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle className={'fd-minWidth'}>
                        {this.props.displayTitle}
                        <IconButton aria-label="Close" style={{ position: 'absolute', right: 12, top: 4 }} onClick={() => this.closeMe(`Cancel`)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {this.state.loading ? (
                        <LoadingIndicator />
                    ) : (
                            <span>

                                {this.state.errorMessage != '' ?
                                    <div className='fd-errorMessage'>{this.state.errorMessage}</div>
                                    : null}
                                <div >
                                    {this.renderForm()}
                                </div>


                            </span>
                        )
                    }
                    {this.state.isWizard ?
                        <MobileStepper
                            steps={this.totalQuestions}
                            position="static"
                            activeStep={this.state.currentQuestion}
                            // className={classes.mobileStepper}
                            nextButton={
                                <Button onClick={() => this.nextQuestion(1)} size="small" disabled={this.state.currentQuestion + 1 === this.totalQuestions}>
                                    Next
                                <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button onClick={() => this.nextQuestion(-1)} size="small" disabled={this.state.currentQuestion === 0}>
                                    <KeyboardArrowLeft />
                                    Back
                             </Button>
                            }
                        /> : null
                    }
                    <DialogActions>
                        {this.cancelButton !== 'DNU' ? (
                            <Button onClick={() => this.closeMe(`Cancel`)} color="default">
                                {this.cancelButton}
                            </Button>) : null
                        }
                        {this.skipButton !== 'DNU' ? (
                            <Button onClick={() => this.closeMe(`Skip`)} color="primary" variant="contained">
                                {this.skipButton}
                            </Button>) : null
                        }
                        {this.OKButton !== 'DNU' ? (
                            <span>
                                {this.state.isWizard === false || ((this.state.currentQuestion + 1) === this.totalQuestions) ?
                                    <Button onClick={() => this.closeMe(`OK`)} color="primary" variant="contained">
                                        {this.OKButton}
                                    </Button> :
                                    <Button onClick={() => this.closeMe(`SaveAndContinue`)} color="primary" variant="contained">
                                        {this.OKButton}
                                    </Button>
                                }
                            </span>) : null
                        }
                    </DialogActions>
                </Dialog>
                :
                    <Fragment>
                        {/* this is the non-dialog version */}
                        <div className='fd-staticForm' style={{height: (this.state.height-70), overflow: 'auto'}}>
                        {this.state.errorMessage != '' ?
                            <div className='fd-errorMessage'>{this.state.errorMessage}</div>
                            : null}
                            {this.renderForm()}
                        </div>
                        <div style={{borderTop:'1px solid #ccc', padding:8}}>             
                            {this.OKButton !== 'DNU' && (
                                    <Button className='floatright' onClick={() => this.closeMe(`SaveAndContinue`)} color="primary" variant="contained">
                                        {'Update'}
                                    </Button>
                            )
                        }
                    </div>
                    </Fragment>
                }
            </div>
        )
    };
}

FormDialog.propTypes = {
    form: PropTypes.object,
    isDialog: PropTypes.bool,
    isOpen: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    formData: PropTypes.object,
    onClose: PropTypes.func,
    displayTitle: PropTypes.string,
    errorMessage: PropTypes.string,
    height: PropTypes.number,
    task: PropTypes.object
};

export default FormDialog;
