import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

const styles = theme => ({
    typography: {
        margin: theme.spacing.unit * 2,
    },
});

// category 1 refers to field name "status" in the notes document note(1), suggestion(2), requirement(3), Approval(4), Decision(5), approved(6),decided(7), resolved(8),not approved(9) 
// category 2 refers to field name "type" in the notes document  active(1), deleted(0), Archive(1)

class Option extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            loggedInUserId: JSON.parse(sessionStorage.getItem("pr_user")).id,
            // note(1), suggestion(2), requirement(3), Approval(4), Decision(5), approved(6),decided(7), resolved(8),not approved(9) 
            //active(1), deleted(0), Archive(1)
            option: [
                { label: "Approve", value: 6, category: 2, belongsTo: 1 }, // 1 belongs to approval,  2 category refers to note type
                { label: "Don't approve", value: 9, category: 2, belongsTo: 1 },
                // 
                { label: "Decide", value: 7, category: 2, belongsTo: 2 }, // 2 belongs to descision, 2 category refers to note type
                // 
                { label: "Resolve", value: 8, category: 2, belongsTo: 3 }, // 3 belongs to resolve, 2 category refers to note type
                // 
                { label: "Archive", value: 2, category: 1, belongsTo: 4 }, //4 belongsTo to  archive. (common for the author)
                { label: "Un-archive", value: 3, category: 1, belongsTo: 7 }, // 7 belongs to Un-archive
                { label: "Edit", value: 0, category: 0, belongsTo: 5 },  //5 belongsTo to  edit.( common for the author), 
                { label: "Delete", value: 0, category: 1, belongsTo: 6 } //6 belongsTo to  delete.( common for the author), 1 category refers to status type
            ],
        };
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };
    type(typeValue, category) {
        if (typeValue == 0 && category == 0) {
            this.props.edit(this.props.noteId);
        } else {
            this.props.type(this.props.noteId, this.props.parentNoteId, typeValue, category);
        }
        this.handleClose();
    }

    render() {
        const { classes, showDelete, authorId, note, parentNote } = this.props;
        let option
        option = this.state.option;
        let assigneeId = null;
        if (note.assigned.length > 0) {
            assigneeId = note.assigned[0].assignedTo._id;
        }
        let archieverId = null;
        if (note.archive.length > 0) {
            archieverId = note.archive[0]._id;
        }

        if (parentNote) { // checking for parent node or child note 
            // note(1), suggestion(2), requirement(3), Approval(4), Decision(5), approved(6),decided(7), resolved(8),not approved(9) 
            if (note.type == 4) // checking for approval 
                if (authorId == this.state.loggedInUserId) { //checking if the logged user is the author of the note
                    if (note.assigned.length > 0 && assigneeId == this.state.loggedInUserId) // checking if the author as assigned to himself along with that the user can edit , delete and archieve
                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6);
                        } else {
                            option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6);
                        }
                    else // author of the note can only edit , delete and archieve
                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6);
                        } else {
                            option = option.filter(x => x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6);
                        }
                } else { // Executes if the logged user is not the author
                    if (note.assigned.length > 0 && assigneeId == this.state.loggedInUserId) // checking if the logged user is assigned to notes 
                        
			if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 7);
                        } else {
                            option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 4);
                        }
                    else // logged user can archive notes
	                if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                             option = option.filter(x => x.belongsTo == 7);
                        } else {
                             option = option.filter(x => x.belongsTo == 4);
                        }
                }
            else if (note.type == 5) // checking for descision 
                if (authorId == this.state.loggedInUserId) { //checking if the logged user is the author of the note
                    if (note.assigned.length > 0 && assigneeId == this.state.loggedInUserId) // checking if the author as assigned to himself along with that the user can edit , delete and archieve
                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 2 || x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6); // logged user can resolve and un-archive
                        } else {
                            option = option.filter(x => x.belongsTo == 2 || x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6); // logged user can resolve and archive
                        }
                    else // author of the note can only edit , delete and archieve
                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6);// logged user can resolve and un-archive
                        } else {
                            option = option.filter(x => x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6); // logged user can resolve and archive
                        }
                } else { // Executes if the logged user is not the author
                    if (note.assigned.length > 0 && assigneeId == this.state.loggedInUserId)

                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 2 || x.belongsTo == 4);// logged user can resolve and un-archive
                        } else {
                            option = option.filter(x => x.belongsTo == 2 || x.belongsTo == 7); // logged user can resolve and archive
                        }
                    else // logged user can archive notes

                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 7);// logged user can resolve and un-archive
                        } else {
                            option = option.filter(x => x.belongsTo == 4); // logged user can resolve and archive
                        }
                }
            else if (note.type == 2) //checking for suggestion 
                if (authorId == this.state.loggedInUserId) { //checking if the logged user is the author of the note

                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 3 || x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6);  // author of the note can only edit , delete, archieve and resolve
                    } else {
                        option = option.filter(x => x.belongsTo == 3 || x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6);  // author of the note can only edit , delete, archieve and resolve
                    }
                } else {
                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 3 || x.belongsTo == 7); // logged user can resolve and un-archive
                    } else {
                        option = option.filter(x => x.belongsTo == 3 || x.belongsTo == 4); // logged user can resolve and archive
                    }
                }
            else if (note.type == 1 || note.type == 3) // checking for note and requirement
                if (authorId == this.state.loggedInUserId) { // //checking if the logged user is the author of the note and allowing to edit, delete and archive
                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6);
                    } else {
                        option = option.filter(x => x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6);
                    }
                } else { // logged user can archive notes
                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 7);
                    } else {
                        option = option.filter(x => x.belongsTo == 4);
                    }
                }
            else if (note.type == 9) // checking if the note is in dont approve type
                if (authorId == this.state.loggedInUserId) {  //checking if the logged user is the author of the note
                    // option = option.filter(x=> x) 
                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 7 || x.belongsTo == 5 || x.belongsTo == 6);
                    } else {
                        option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 4 || x.belongsTo == 5 || x.belongsTo == 6);
                    }
                } else {
                    if (note.assigned.length > 0 && assigneeId == this.state.loggedInUserId) // checking if the logged user is assigned to notes 
                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 7);
                        } else {
                            option = option.filter(x => x.belongsTo == 1 || x.belongsTo == 4);
                        }
                    else // logged user can archive notes
                        if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                            option = option.filter(x => x.belongsTo == 7);
                        } else {
                            option = option.filter(x => x.belongsTo == 4);
                        }
                }
            else
                if (authorId == this.state.loggedInUserId) {
                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 7 || x.belongsTo == 6);
                    } else {
                        option = option.filter(x => x.belongsTo == 4 || x.belongsTo == 6);
                    }
                } else {
                    if (note.archive.length > 0 && archieverId == this.state.loggedInUserId) { // checking if the author as archived a note to show un-archive
                        option = option.filter(x => x.belongsTo == 7);
                    } else {
                        option = option.filter(x => x.belongsTo == 4);
                    }
                }
            // 
        } else {
            if (authorId == this.state.loggedInUserId) {
                option = option.filter(x => x.belongsTo == 5 || x.belongsTo == 6);
            }
        }

        if (!showDelete) { // checking to show the delete or not
            option = option.filter(x => x.belongsTo != 6);
        }

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        // To show the option based on note type
        return (
            <div>
                <IconButton aria-owns={open ? 'option' : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Popover
                    id="option"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Typography component={'span'}>
                        <List component="nav">
                            <Typography component={'span'}>{option.map((op, i) => {
                                return <ListItem button onClick={() => this.type(op.value, op.category)}>
                                    <ListItemText primary={op.label} />
                                </ListItem>
                            })}</Typography>
                        </List>
                    </Typography>
                </Popover>
            </div>
        );
    }
}

Option.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Option);