import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from '@material-ui/core';
import AppData from '../../../shared/AppData';


import '../../../css/ProductRunner.scss';

const avatarColors = ['avatar-color0', 'avatar-color1', 'avatar-color2', 'avatar-color3'];
const avatarColorCount = avatarColors.length;

class UserAvatar extends Component {
    constructor(props) {
        super(props);

        let colorIndex = 0;
        for (let i = 0; i < props.username.length; i++) {
            colorIndex = props.username.charCodeAt(i);
        }
        const ac = avatarColors[colorIndex % avatarColorCount];

        const initials = props.username.charAt(0).toUpperCase();

        this.state = {
            userInitials: initials,
            colorClass: ac,
            sizeClass: props.size === 'sm' ? 'avatar-small' : '',
            image: props.imageUrl,
        };
    }


    render() {
        const props = this.props;
        return (
            <Fragment>
                {this.state.imageUrl ?
                    <Avatar alt={this.props.username} src={this.state.imageUrl} className={this.state.colorClass}>T</Avatar>
                    :
                    <Avatar className={`${this.state.colorClass} ${this.state.sizeClass}`} >
                        {this.state.userInitials}
                    </Avatar>
                }
            </Fragment>
        );
    }
}

UserAvatar.propTypes = {
    username: PropTypes.string,
    size: PropTypes.string,
    imageUrl: PropTypes.string,
    colorIndex: PropTypes.number
};

export default UserAvatar;
