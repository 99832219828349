import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import api from '../../../shared/api';
import ProfileAvatar from './ProfileAvatar';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    typography: {
        margin: theme.spacing.unit * 2,
    },
    card: {
        width: 280,
        //maxHeight: 450,
    },
    media: {
        height: 120,
    },
    bigAvatar: {
        width: 280,
        height: 200,
        fontSize: 135,
        borderRadius: 0
    }
});


class Profile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
            userDetails: [],
            username: this.props.username
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            username: nextProps.username
        })
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
        this.loadUser(this.state.username);
    };

    loadUser = async (username) => {
        try {
            const userDetails = await api.get('/note/get/user-detail/' + username);
            this.setState({
                userDetails: userDetails.data.data.data[0]
            })
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    sendMail = async (event) => {
        try {
            const sendMail = await api.post('/note/send/mail', {
                to: '',
                subject: 'Test mail',
                body: 'Hi this is test mail'
            });
        } catch (e) {
            alert(e);
        }
    }

    render() {
        const { classes } = this.props;
        const { anchorEl, userDetails } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div>
                <Link
                    className={classes.link}
                    aria-owns={open ? 'simple-popper' : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleClick}
                    style={this.props.isMention == false ? { color: 'rgba(0,0,0,0.87)' } : { color: '#ff5959' }}
                >
                    {this.props.name}
                </Link>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Card className={classes.card}>
                        <CardActionArea>
                            <Grid container justify="center" alignItems="center">
                                <ProfileAvatar username={this.props.name.charAt(0) == '@' ? this.props.name.charAt(1) : this.props.name} />
                            </Grid>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {userDetails['firstName']}
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    <Table >
                                        <TableRow>
                                            <TableCell>Role : {userDetails['roles']}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Email : {userDetails['username']}</TableCell>
                                        </TableRow>
                                    </Table>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" href={'mailto:' + userDetails['username']} /*onClick={(e) => this.sendMail(this)}*/ >
                                Email
                            </Button>
                        </CardActions>
                    </Card>
                </Popover>
            </div>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
