import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';
import BoardTop from '../Board/BoardTop';

import '../../../css/ProductRunner.scss';

const boardColors = ['board-color0', 'board-color1', 'board-color2', 'board-color3'];
const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    rh: {
        padding: `6px 18px`,
        height: 24
    },
    tlink: {
        color: '#555'
    }
});

class OneList extends Component {
    constructor(props) {
        super(props);

        let ci = 0;
        if (props.index) {
            ci = props.index % 4;
        }

        const { asset, taskId } = props;
        this.state = {
            taskId,
            nextList: asset,
            view: 'list',       // list, kanban, type, masterlist
            width: 'small',
            barColor: boardColors[ci]
        };
    }

    percentComplete = (percent) => {
        if (!percent) {
            return 1;
        } else {
            return (50+percent);
        }
    }

    // *******************************************
    //
    //    gadgets 
    //
    // *******************************************

    openMenu = () => {
        this.setState({ menuOpen: true });
    };

    closeMenu = () => {
        this.setState({ menuOpen: false });
    };

    doMenuItem = (index) => {
        this.props.onMenu(index, this.state.menuSelection);
    }

    render() {
        const { classes, index } = this.props;
        const { nextList } = this.state;
        const ind = index ? index : 0;
        return (
            <div>
                {nextList.name && (
                    <BoardTop
                        title={`To Do: ${nextList.name}`}
                        index={ind}
                    />

                )}
                <div className={'tl-Box'} style={{ height: 400, overflow: `auto` }}>
                        <Table className={classes.table}>
                            <TableHead>
                                {/*}
                                <TableRow className={classes.rh}>
                                      <TableCell align="left"  className={classes.rh}>TO DO</TableCell>
                                    <TableCell align="center"  className={classes.rh}>PROGRESS</TableCell>
                                </TableRow>
                */}
                            </TableHead>
                            <TableBody>
                                {nextList.list.map(nextTask => (
                                    <TableRow key={nextTask._id} className={classes.rh}>
                                        <TableCell  className={classes.rh}>
                                        <Link className={classes.tlink} to={`/task?id=${nextTask.task}&name=${nextTask.assetName}&type=${nextTask.assetType}&parentTask=${this.state.taskId}`}>
                                     {/*}   <Link className={classes.tlink} to={`/task?id=${this.state.taskId}&name=${nextTask.assetName}&type=${nextTask.assetType}&subTask=${nextTask.task}`}> */}
                                          <i className="far fa-square" style={{ color: '#666', marginRight: 8 }}/> 
                                        {nextTask.name}
                                           </Link>
                                        </TableCell>
                                        <TableCell align="center"  className={classes.rh}>
                                            <LinearProgress variant="determinate" value={this.percentComplete(nextTask.percentComplete)} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                </div>
            </div>
        );
    }
}

OneList.propTypes = {
    taskId: PropTypes.string,
    asset: PropTypes.object,
    width: PropTypes.string,
    index: PropTypes.number
};

export default withStyles(styles, { withTheme: true })(OneList);
