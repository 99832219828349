import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import Note from '../../../components/User/Note/Note';
import api from '../../../shared/api';
import FormDialog from '../../../components/FormDialog/FormDialog';
import BoardTop from '../../../components/User/Board/NoteBoardTop';

import ScrollToTop from 'react-scroll-up';
import Img from 'react-image';
const scrollTop = require('../../../img/pr/images/scrollTop.png');



const styles = theme => ({
    locationTitle: {
        minWidth: 300,
        maxWidth: 300,
        textTransform: 'uppercase'
    },
    locationNote: {
        minWidth: 300,
        maxWidth: 300,
        minHeight: 100,
        marginTop: -35
    }
})

const options = [
    { label: 'All', key: 1, value: false, disabled: false },
    { label: 'Active', key: 2, value: true, disabled: false },
    { label: 'Archived', key: 3, value: false, disabled: false },
    { label: 'Assigned to me', key: 4, value: false, disabled: false }
];

class LocationNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openLocDiaglog: false,
            users: [],
            note: [],
            filterSelected: [{ label: 'Active', key: 2, value: true }], //2 = Active notes
            filter: false,
            productId: this.props.productId,
            taskId: this.props.taskId,
            locationType: this.props.locationType,
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle: "", // this is what the user sees (could be blank)
        };
    }

    componentWillMount() {
        this.loadUser();
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            openLocDiaglog: nextProps.open,
            productId: nextProps.productId,
            taskId: nextProps.taskId,
            locationType: nextProps.locationType,
        })
    }

    handleClickOpen = () => {
        this.setState({ openLocDiaglog: true });
    };

    handleClose = () => {
        this.setState({ openLocDiaglog: false });
    };

    addNewNote = (columnNumber) => {
        // To load user for assigning 
        this.loadUser();
        this.openDialog('Note', 'Add a Note');
    }

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    menu = (menuSelection) => {
        let currentSelectedMenu = [];
        let newFilter = menuSelection.filter(x => x.value == true);
        if (newFilter.length == 0) {
            currentSelectedMenu = this.state.filterSelected;
        } else {
            currentSelectedMenu = newFilter;
        }
        this.setState(
            { filterSelected: currentSelectedMenu, filter: true },
            () => {
                this.setState({ filter: false });
            }
        );
    }

    formClosed = async (formName, response) => {
        if (formName == 'Note' && response.status === 'OK') {
            const { formData } = response;
            let err = '';
            if (err !== '') {
                err = "Oh no, not quite right: " + err;
                this.setState({ errorMessage: err });
            } else {
                // ********* To add a note *********
                try {
                    const note = await api.post('/note/create', {
                        productId: this.state.productId,
                        taskId: this.state.taskId,
                        location: this.state.locationType,
                        subject: response.formData.Subject,
                        note: response.formData.Note,
                        type: response.formData.Type,
                        author_id: JSON.parse(sessionStorage.getItem("pr_user")).id,
                        assignedTo: response.formData.Assign
                    });

                    if (note.data.data.success) {
                        let currentNotes = [];
                        this.setState({ formDialogOpen: false });
                        currentNotes = [note.data.data.data, ...currentNotes];
                        this.setState(
                            { note: currentNotes },
                            () => {
                                //                 // do this when setState() is finished...
                                this.setState({ note: [] });
                            }
                        );
                        // this.refs.child.showReply(note.data.data.data._id);
                        response.formData.Subject = '';
                        response.formData.Note = '';

                        //check the locationType notes count empty or not, if not push locationType to array to change bubble color
                        let resArr = [];
                        api.get('/note/get/locationCount/' + this.state.productId + '/' + this.state.taskId + '/' + this.state.locationType).then((result) => {
                            if(result.data.data.data.count > 0) {
                                resArr.push({
                                    locationType : this.state.locationType,
                                    isAdd : true,
                                    isDelete : false
                                });
                                this.storeLocationsArr(resArr);
                            }
                        })

                    } else {
                        this.setState({ errorMessage: note.data.data.errMsg });
                    }
                } catch (e) {
                    // do this if api.get() throws an error;
                    alert(e);
                }
            }
        } else {
            this.setState({ formDialogOpen: false });
        }
    }

    storeLocationsArr = (locationsArr) => {
        this.props.storeLocationsValue(locationsArr);
    }

    // To all the user for the product 
    loadUser = async (noteId = "false") => {
        // To get all the note for a task
        try {
            const users = await api.get('/note/users/all/');
            this.setState(
                {
                    users: users.data.data.data,
                },
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }

    scrollTOTop = () => {
        this.componentRef.current.scrollTop = 0;

    }

    render() {
        const { classes } = this.props;
        const { note } = this.state;

        return (
            <div>
                <Dialog
                    open={this.state.openLocDiaglog}
                    onClose={this.handleClose}
                    aria-labelledby="confirmation-dialog-title"
                >
                    <DialogTitle id="confirmation-dialog-title" className={classes.locationTitle}>{this.props.locationType}
                        <BoardTop
                            subTitle={'Add Notes to ' + this.props.locationType + ' here...'}
                            index={1}
                            onAdd={this.addNewNote}
                            onMenu={this.menu}
                            menu={options}
                        />

                    </DialogTitle>
                    <br />
                    <DialogContent className={classes.locationNote}>
                        <div>
                            <Note
                                newNote={note}
                                users={this.state.users}
                                onUser={this.loadUser}
                                onFilter={this.state.filterSelected}
                                filter={this.state.filter}
                                addNote={this.addNewNote}
                                productId={this.props.productId}
                                taskId={this.props.taskId}
                                locationType={this.props.locationType}
                                storeLocationsValue={this.storeLocationsArr}
                            />
                        </div>
                        <FormDialog
                            isOpen={this.state.formDialogOpen}
                            displayTitle={this.state.formDisplayTitle}
                            formName={this.state.formName}
                            formData={null}
                            onClose={this.formClosed}
                            users={this.state.users}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(LocationNote);
