import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';


import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';

import '../../../css/ProductRunner.scss';
import genericPrImage from '../../../img/pr/images/pr-photo.png';

const boardColors = ['board-color0', 'board-color1', 'board-color2', 'board-color3'];

class ProductCard extends Component {
    constructor(props) {
        super(props);

        let ci = 0;
        if (props.index) {
            ci = props.index;
        }

        const { product } = props;
        this.state = {
            productId: product._id,
            mini: props.mini,
            list: props.list,
            image: product.image,
            title: product.name,
            date: product.creationDate,
            author: product.author,
            percentage: product.percentage > 0 ? product.percentage : 5,
            barColor: boardColors[ci],
            menuOpen: false,
        };
    }

    imageDidNotLoad = () => {

    }

    // *******************************************
    //
    //    gadgets 
    //
    // *******************************************

    openMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    closeMenu = () => {
        this.setState({ menuOpen: false });
    };

    doMenuAction = (menuItem) => {
        this.setState({ menuOpen: false });
        this.props.menuAction(menuItem, this.state.productId);
    }


    render() {
        const props = this.props;
        const { title, image } = this.state;
        const imageStyle = {
            background: `url(${genericPrImage}) center center`, width: '100%', borderRadius: '4px'
        }
        return (
            <span>
                <div className={`pcard`}>
                    <Link
                        to={`/product?id=${this.state.productId}`}
                        className="realm--house-card-detail-link"
                    >
                        <div className={`pcard-image`} style={imageStyle}>
                        </div>
                    </Link>
                    <div className={`pcard-tool`} onClick={this.openMenu}>
                                <i className={`fa fa-angle-down ${this.state.menuOpen ? 'pcard-tool-close-icon' : 'pcard-tool-open-icon'}`} aria-hidden="true"></i>
                     </div>
                     { this.state.menuOpen && (
                                   <div className={`pcard-tool-open`}>
                                       {props.menu.map((menuItem, index) => (
                                           <div className={`pcard-tool-option`} onClick={() => (this.doMenuAction(menuItem))}>
                                               {menuItem}
                                           </div>
                                       ))}
                                   </div>
                                )}
                    <div className={`pcard-bottom`}>
                        <table className={`pcard-bottom-table`}>
                            <tr>
                                <td>
                                    <Link
                                        to={`/product?id=${this.state.productId}`}
                                        className="white-link"
                                    >
                                        {title}
                                    </Link>

                                    <div className={`pcard-bottom-subtitle`}>
                                        Created: {this.state.date}
                                    </div>
                                </td>
                                <td className={`pcard-bottom-right`}>
                                    <Link
                                        to={`/product?id=${this.state.productId}`}
                                    >
                                        <Button size='small' className={`pcard-bottom-button`}>Open</Button>
                                    </Link>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </span>
        );
    }
}

ProductCard.propTypes = {
    product: PropTypes.object,
    mini: PropTypes.bool,
    list: PropTypes.bool,
    index: PropTypes.number,
    menu: PropTypes.array,
    menuAction: PropTypes.func
};
export default ProductCard;
