import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// ******************* global data *****************
import { Dialog, DialogActions, DialogTitle, Button, Slide } from '@material-ui/core';


// ********************** css **********************
import './styles.scss';

// ********************** images ********************

class Alert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iAmOpen: false,
            button1: props.button1 || 'OK',
            button2: props.button2 || false,
            cancel: props.cancel || false,
            class: props.class ? props.class : 'white-title',   // this describes the form to the SYSTEM
        };
    }

    
    Transition(props) {
        return <Slide direction="down" {...props} />;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            iAmOpen: nextProps.isOpen,
            button1: nextProps.button1 || 'OK',
            button2: nextProps.button2 || false,
            cancel: nextProps.cancel || false,
            class: nextProps.class ? nextProps.class : 'white-title',   // this describes the form to the SYSTEM
        });
    }

    closeMe(action, actionId) {
        if (this.props.doAction) {
            this.props.doAction(action, actionId, this.props.action, this.props.objectId);
        } else {
            this.setState({ iAmOpen: false });
        }
    }

    render() {
        const props = this.props;
        return (
            <Fragment>
                <Dialog
                    open={this.state.iAmOpen}
                    TransitionComponent={this.Transition}
                    keepMounted
                   // onClose={() => this.closeMe(`Cancel`)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby={`alert-dialog-${props.title}`}
                >
                    <DialogTitle className={'fd-minWidth'}>
                        {props.title}

                    </DialogTitle>

                    <div class='alert-message'>
                        {props.message}
                    </div>

                    <DialogActions>
                    <Button onClick={() => this.closeMe('Button1', 1)} color="primary">
                            {this.state.button1}
                        </Button>
                        {this.state.button2 ? (
                            <Button onClick={() => this.closeMe('Button2, 2')} color="default">
                                {this.state.button2}
                            </Button>) : null
                        }
                       {this.state.cancel ? (
                            <Button onClick={() => this.closeMe(`Cancel`, -1)} color="default">
                                Cancel
                            </Button>) : null
                        }

                    </DialogActions>
                </Dialog> 
            </Fragment>
        );
    }
}

Alert.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    headline: PropTypes.string,
    message: PropTypes.string,
    button1: PropTypes.object,
    button2: PropTypes.object,
    cancel: PropTypes.bool,
    doAction: PropTypes.func,
    action: PropTypes.string,
    objectId: PropTypes.string,
    class: PropTypes.string
};
export default Alert;