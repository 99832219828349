import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';

import '../../../css/Marketing.scss';


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formDialogOpen: false,
            formName: 'Register',   // this describes the form to the SYSTEM
            formDisplayTitle: `Welcome, please register. It's free.`, // this is what the user sees (could be blank)
            errorMessage: ''
        };
    }


    // *******************************************
    //
    //    sign in dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle, errorMessage: '' });
    };

    closeRegister = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = async (formName, response) => {
        if( response.status === 'OK') {
            const {formData} = response;
            let err = '';
            if( formData.Password !== formData['Confirm Password']) {
                err += "Your passwords don't match. "
            }
            if( formData.Terms !== true) {
                err += "You gotta accept the terms. "
            }
            if( formData['Last Name'].length < 2 ) {
                err += "Well, we really need a bit more last name for you."
            }
            if( formData['Password'].length < 4 ) {
                err += `Um, that passowrd's a bit too short.`
            }
            if( err !== '') {
                err = "Oh no, not quite right: " + err;
                this.setState( {errorMessage: err});   
            } else {
                // ********* try to register the person now!
                try {
                    const user = await api.post('/user/regi', {
                        email: response.formData.Email,
                        firstName: response.formData['First Name'], 
                        lastName: response.formData['Last Name'], 
                        password: response.formData.Password,
                        note: response.formData.Note
                    });
                    if (user.data.data.success) {
                        this.setState(
                            {
                                user: user.data.data.data,
                                formDialogOpen: false
                            },
                            () => {
                                // do this when setState() is finished...
    
                                AppData.temporarySignUserIn();
                            }
                        );
                    } else {
                        this.setState({errorMessage: user.data.data.errMsg });
                    }
                } catch (e) {
                    // do this if api.get() throws an error;
                    alert(e);
                }
            }
        } else {
            this.setState( {formDialogOpen: false });
        }


    }


    render() {
        return (
            <span>
              <a onClick={() => this.openDialog(this.state.formName, this.state.formDisplayTitle)}>Register</a>

                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    errorMessage={this.state.errorMessage}
                    onClose={this.formClosed}
                />
            </span>
        );
    }
}

export default Register;
