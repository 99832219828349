import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import merge from 'lodash.merge';

// *********** Material UI *************
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent, FormControlLabel, Tooltip, withStyles } from '@material-ui/core';
import { Button, TextField, Checkbox, FormControl, Select, InputLabel, FormHelperText, Input, MenuItem, Snackbar, IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';


import UserAvatar from '../../../components/User/UserAvatar/UserAvatar';
import 'font-awesome/css/font-awesome.min.css';
import Profile from '../Note/Profile';
import reactStringReplace from 'react-string-replace';
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import CardHeader from '@material-ui/core/CardHeader';

// **********  PR Components ****************
import api from '../../../shared/api';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import AppData from '../../../shared/AppData';
import { EINTR } from 'constants';
import LocationNote from '../Note/LocationNote';

// ******** CSS *******************************
import './FormPage.scss';

// ******** WYSIWYG EDITOR *********************
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { blue } from '@material-ui/core/colors';
import { async } from 'q';

// ************* mentions
import { MentionsInput, Mention } from 'react-mentions'
import defaultStyle from '../../User/Note/defaultStyle'
import defaultMentionStyle from '../../User/Note/defaultMentionStyle'


// ******************* FormPage Component ******************

class FormPage extends Component {
    constructor(props) {
        super(props);
        const responses = (props.form && props.form.responses) ? props.form.responses : {};
        const ct = props.form.template;
        const cn = props.form.templateName;

        this.state = {
            isDialog: false,
            task: props.task,
            form: props.form,
            formHeight: 800,
            updated: false,
            loading: false,
            name: cn,
            template: ct,
            firstTime: true,
            errorMessage: '',
            hasData: false,
            responses,
            currentQuestion: 0,
            formXPos: 0,
            openConfirm: false,
            editorState: EditorState.createEmpty(),
            openLocationDialog: false,
            locationType: null,
            productId: this.props.productId,
            taskId: this.props.taskId,
            expanded: null,
            locationTypesArr: [],
            sectionWiseNotes: [],
            locationNotes: [],
            locationFields: [],

        };
        this.onChangeText = this.onChangeText.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.required = {};
        this.changed = {};
        this.original = {};
    }

    static cancelButton = 'Cancel';
    static skipButton = 'Skip';
    static OKButton = 'OK';
    static totalQuestions = 0;


    componentWillMount() {
        const { template } = this.state;
        if (!template) {
            return;
        }
        let res = [];
        let locationFieldsRes = [];
        // ********************* get fields names and attach notes to each **************
        template.form.map((nextQuestion, index) => {
            const fieldName = nextQuestion.fieldName ? nextQuestion.fieldName : `Question ${index}`;
            this.changed[fieldName] = false;
            this.original[fieldName] = '';

            // To get the current notes count of the each locations

            api.get('/note/get/locationCount/' + this.state.productId + '/' + this.state.taskId + '/' + fieldName).then((result) => {
                if (result.data.data.data.count > 0) {
                    res.push({
                        locationType: fieldName,
                        locationTypeNotes: result.data.data.data.notes,
                        locationTypeCount: result.data.data.data.count
                    })
                    locationFieldsRes.push(fieldName)
                    this.setState({
                        locationFields: locationFieldsRes,
                        locationNotes: res,
                    })
                    this.storeLocations();
                }
            })
            if (this.state.locationTypesArr.includes(fieldName) === false) {
                this.state.locationTypesArr.push(fieldName);
            }
        })
    }

    componentDidMount() {
    }

    storeLocations = (locationArr) => {
        if (locationArr !== undefined) {
            if (locationArr[0].isAdd === true) {
                if (this.state.locationFields.includes(locationArr[0].locationType)) {
                    this.setState({
                        locationFields: this.state.locationFields
                    })
                } else {
                    let res = this.state.locationFields;
                    res.push(locationArr[0].locationType);
                    this.setState({
                        locationFields: res
                    })
                }
            }
            if (locationArr[0].isDelete === true) {
                this.setState({
                    locationFields: this.state.locationFields.filter(function (item) {
                        return item !== locationArr[0].locationType
                    })
                })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            productId: nextProps.productId,
            taskId: nextProps.taskId,
            openLocationDialog: false,
        })
    }

    // *******************************************
    //
    //    form dialog 
    //
    // *******************************************
    TransitionUp(props) {
        return <Slide direction="up" {...props} />;
    }

    TransitionRight(props) {
        return <Slide {...props} direction="right" />;
    }

    openMe = () => {
        this.setState({ iAmOpen: true });
    };

    onChangeText = (e) => {
        const id = e.target.id;
        const text = e.target.value;
        let value = {};
        value[id] = text;
        const newState = merge({}, this.state.responses, value);
        this.setState({ responses: newState });
        this.changed[id] = true;
    };

    onBlur = (e) => {
        const id = e.target.id;
        if (this.changed[id] === true) {
            this.setState({ openConfirm: true });
            this.changed[id] = false;
        }
    }

    onHandleCheckbox = (e) => {
        const fieldName = e.target.id;
        const newState = merge({}, this.state.responses, { [fieldName]: e.target.checked });
        this.setState({ responses: newState });
    };

    onHandlePickList = (event, fieldName) => {
        const newState = merge({}, this.state.responses, { [fieldName]: event.target.value });
        this.setState({ responses: newState });
    }

    closeMe = (button) => {
        // button is one of: 'Cancel', 'Skip', 'OK'

        let valid = true;
        if (button === 'OK' && this.state.template.validate === true) {
            // then need to validate the form.
            for (const key of Object.keys(this.required)) {

                const value = this.state.responses[key] ? this.state.responses[key] : null;
                if (!value || value === '' || value === null) {
                    valid = false;
                }
            }

            if (!valid) {
                this.setState({ errorMessage: `Sorry, please complete this form' required fields` });
                return;
            }
        }

        let response = { action: 'close', status: button, data: this.state.responses };
        if (this.props.onClose) {
            this.setState({ loading: true });
            this.props.onClose(this.props.name, response);
        }
    };

    onKeyDown = (e, type) => {
        const { keyCode } = e;
        if (this.forwardKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf' || keyCode !== 13) {
                this.nextQuestion(1);
            }
        }
        if (this.backKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf') {
                this.nextQuestion(-1);
            }
        }
        return null;
    }

    handleCloseSnack = () => {
        this.setState({ openConfirm: false });
    };

    // ********************************************
    //
    // deal with Editor
    // 
    // ********************************************
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    // ************************
    //
    //  openLocationDialog  
    //
    // ************************

    openLocDialog = async (locationType) => {
        this.setState({
            openLocationDialog: true,
            locationType: locationType
        })
    }

    handleClose = () => {
        this.setState({
            openLocationDialog: false,
        });
    };

    // ************************************************
    //
    // render form item logic 
    //
    // ************************************************
    renderFormItem = (item) => {
        const { classes } = this.props;
        const { type, prompt, placeholder, required, fieldName, label, list } = item;
        const field = fieldName ? fieldName : prompt;
        if (required) {
            this.required[field] = type;
        }

        const questionClass = this.state.isWizard ? 'fd-wizardPanel' : 'fd-wizardQuestion';

        // NOTE THESE ARE THE DISPLAY VERSIONS!!!!
        const response = this.state.responses[field] ? this.state.responses[field] : '';
        const title = prompt ? prompt : field;
        return (
            <div id={field}>
                <div className='form-page-form-title'>{title}
                    <div className='form-page-form-buttons'>
                        <Tooltip disableFocusListener title={prompt} classes={{ tooltip: classes.lightTooltip }}>
                            <a className={`canvas-info`}>
                                <i className="fa fa-info-circle" aria-hidden="true" ></i>
                            </a>
                        </Tooltip>
                        {this.state.locationFields.includes(fieldName) ?
                            <a className={`canvas-info`}>
                                <i className="far fa-comment-dots" style={{ color: '#ff5959' }} aria-hidden="true" onClick={() => this.openLocDialog(fieldName)}></i>
                            </a> :
                            <a className={`canvas-info`}>
                                <i className="far fa-comment-dots" aria-hidden="true" onClick={() => this.openLocDialog(fieldName)}></i>
                            </a>
                        }
                    </div>
                </div>
                <div className='form-page-form-response'>{response}</div>
            </div>
        )
        switch (type) {
            case 'row':
                return (
                    <div style={{ height: 40, background: 'gray' }}>
                        {prompt}
                    </div>
                );

            case 'password':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onKeyDown={this.onKeyDown}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            fullWidth
                        />
                    </div>
                );
            case 'text':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onKeyDown={(event) => this.onKeyDown(event, 'tf')}
                            placeholder={placeholder}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        />
                    </div>
                );
            case 'textarea':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            placeholder={placeholder}
                            margin="normal"
                            // variant="outlined"
                            multiline
                            rowsMax="4"
                            fullWidth
                            variant="outlined"
                        />
                    </div>
                )

            case 'singlecheck':
                return (
                    <div className={questionClass}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={fieldName}
                                    required={required}
                                    onChange={this.onHandleCheckbox}
                                    value={this.state.responses[fieldName]}
                                />
                            }
                            label={label}
                        />
                    </div>
                )
            case 'picklist':
                return (
                    <div className={questionClass}>
                        <FormControl style={{ margin: 0, minWidth: 350 }} >

                            <Select style={{ marginTop: 20 }}
                                id={fieldName}
                                name={fieldName}
                                value={this.state.responses[fieldName]}
                                onChange={(event) => this.onHandlePickList(event, fieldName)}
                                displayEmpty
                            // className={'fd-selectEmpty'}
                            >
                                <MenuItem value="">
                                    <em>Please choose...</em>
                                </MenuItem>
                                {list.map((item) => (

                                    <MenuItem value={item.value}>
                                        {item.item}

                                        {/*}
                                    <div value={item.value}>
                                        <div style={{ color: 'black' }} value={item.value}>{item.item}</div>
                                        {item.hint ? <div style={{ padding: '0px 8px', color: '#ccc', fontSize: 12, fontStyle: 'italic' }}>{item.hint}</div> : null}
                                    </div>
                                */}
                                    </MenuItem>

                                ))}
                            </Select>
                            <FormHelperText>{prompt}</FormHelperText>
                        </FormControl>
                    </div>
                )
            case 'select':
                return (<div className={questionClass}>
                    <FormControl style={{ margin: 0, minWidth: 350 }} >

                        <Select style={{ marginTop: 20 }}
                            id={fieldName}
                            name={fieldName}
                            value={this.state.responses[fieldName]}
                            onChange={(event) => this.onHandleSelect(event, fieldName)}
                        >
                            <MenuItem value="">
                                <em>{fieldName}</em>
                            </MenuItem>
                            {this.props.users.map((u) => (
                                <div value={u._id}>
                                    <div style={{ color: 'black' }} value={u._id}>{u.fullName}</div>
                                </div>
                            ))}
                        </Select>
                        <FormHelperText>{fieldName}</FormHelperText>
                    </FormControl>
                </div>)
            case 'mention-textarea':
                const mentionDataFormat = this.props.users.map(({ _id, username }) => ({ id: _id, display: username })); // Formatting according to mentions plugin data format
                return (<div className={questionClass}>
                    <FormControl style={{ margin: 0, minWidth: 350 }} >
                        <MentionsInput
                            value={this.state.responses[fieldName]}
                            onChange={(event) => this.onHandletextareaWithMentions(event, fieldName)}
                            style={defaultStyle}
                            markup="@[__display__](##__id__)"
                            placeholder={""}
                            displayTransform={this.mentionDisplayTransform}
                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={mentionDataFormat}
                                renderSuggestion={this.renderUserSuggestion}
                                // onAdd={onAdd}
                                style={defaultMentionStyle}
                            />

                            {/* <Mention
                                type="email"
                                trigger={emailRegex}
                                data={search => [{ id: search, display: search }]}
                                // onAdd={onAdd}
                                style={{ backgroundColor: '#d1c4e9' }}
                            /> */}
                        </MentionsInput>
                        <FormHelperText>{fieldName}</FormHelperText>
                    </FormControl>
                </div>)

            default:
                return (
                    <div style={{ height: 40, background: 'black', color: 'white', textAlign: 'center' }} className={questionClass}>
                        {prompt}
                    </div>
                );
        }
    }


    renderFromTemplate = (formTemplate, firstTime) => {
        if (!formTemplate) {
           // return (null);
           return(<div>no template</div>)
        }
        const { form, buttons } = formTemplate;

        const formContentClass = this.state.isWizard ? 'fd-wizardContent' : null;
        this.totalQuestions = form.length;

        if (buttons) {
            if (buttons.cancel) {
                this.cancelButton = buttons.cancel;
            }
            if (buttons.skip) {
                this.skipButton = buttons.skip;
            }
            if (buttons.ok) {
                this.OKButton = buttons.ok;
            }
        }
        return (
            <div className={formContentClass} style={{ marginLeft: this.state.formXPos, transition: 'all 0.3s ease-in-out 0s' }}>
                {form.map((item) => {
                    return (this.renderFormItem(item))
                }
                )}
            </div>
        )
    }

    renderForm = () => {
        const { name } = this.state;
        if (name === '' || this.state.iAmOpen === false) {
            return (<div>Loading...</div>);
        } else {
            return (this.renderFromTemplate(this.state.template, false));
        }
    }

    renderGrid = () => {
        const { name } = this.state;
        if (this.state.loading) {
            return (<div>Loading...</div>);
        } else {
            return (this.renderFromTemplate(this.state.template, false));
        }
    }

    renderAccordian = () => {
        const { expanded } = this.state;
        const { classes } = this.props;
        const calendarStrings = {
            lastDay: 'LT [Yesterday] ',
            sameDay: 'LT [Today]',
            nextDay: 'LT [Tomorrow]',
            lastWeek: '[last] dddd [at] LT',
            nextWeek: 'dddd [at] LT',
            sameElse: 'L'
        };
        const { name } = this.state;
        if (this.state.loading) {
            return (<div>Loading...</div>);
        } else {
            return (
                <div>
                    <hr></hr>
                    <h3>Notes by Section</h3>
                    <hr></hr>
                    <ExpansionPanel expanded={expanded === 'panel'} onClick={() => this.getNotes('noLocation')} onChange={this.handleChange('panel')} style={{ marginTop: 10 }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>{this.props.formTitle}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.sectionWiseNotes.length > 0 ?
                                <Grid container spacing='24' style={{ paddingRight: '60px' }}>
                                    {this.state.sectionWiseNotes.map((note, i) => {
                                        return (
                                            <Grid item xs={4}>
                                                <div className={classes.cardList} style={{ fontFamily: "Montserrat", lineHeight: '25px', paddingLeft: '8px' }}>
                                                    <CardHeader
                                                        avatar={
                                                            <UserAvatar username={note.author.firstName} size={'sm'} />
                                                        }
                                                        title={<Profile name={note.author.firstName} username={note.author.username} isMention={false} />}
                                                        subheader={<Moment calendar={calendarStrings} date={note.creationDate} />}
                                                    />
                                                    <Divider />
                                                    <p><b>Subject: </b>{note.subject}</p>
                                                    <Divider />
                                                    <p><b>Message: </b>{this.renderTheString(note)}</p>
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                : <p><b>No Notes</b></p>}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {this.state.locationTypesArr.map((item, index) => {
                        return (
                            <ExpansionPanel expanded={expanded === 'panel' + index} onClick={() => this.getNotes(item)} onChange={this.handleChange('panel' + index)} style={{ marginTop: 10 }}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>{item}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {this.state.sectionWiseNotes.length > 0 ?
                                        <Grid container spacing='24' style={{ paddingRight: '60px' }}>
                                            {this.state.sectionWiseNotes.map((note, i) => {
                                                return (
                                                    <Grid item xs={4}>
                                                        <div className={classes.cardList} style={{ fontFamily: "Montserrat", lineHeight: '25px', paddingLeft: '8px' }}>
                                                            <CardHeader
                                                                avatar={
                                                                    <UserAvatar username={note.author.firstName} size={'sm'} />
                                                                }
                                                                title={<Profile name={note.author.firstName} username={note.author.username} isMention={false} />}
                                                                subheader={<Moment calendar={calendarStrings} date={note.creationDate} />}
                                                            />
                                                            <Divider />
                                                            <p><b>Subject: </b>{note.subject}</p>
                                                            <Divider />
                                                            <p><b>Message: </b>{this.renderTheString(note)}</p>
                                                        </div>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : <p><b>No Notes</b></p>}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                    }
                </div>
            )
        }
    }

    handleChange = panel => async (event, expanded, locationType) => {
        this.setState({
            expanded: expanded ? panel : false,
            openLocationDialog: false
        });
    };

    getNotes = async (locationType) => {
        try {
            const notesResult = await api.get('/note/get/locationCount/' + this.state.productId + '/' + this.state.taskId + '/' + locationType)
            this.setState({
                sectionWiseNotes: notesResult.data.data.data.notes
            })
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }

    noteStatus = (value) => {
        switch (value) {
            case 1:
                return (null);
            case 2:
                return (
                    <Typography> <Tooltip title="Suggestion"><i className="fa fa-lightbulb fa-2x" style={{ color: '#4d4dff' }}></i></Tooltip></Typography>
                    // <svg xmlns="http://www.w3.org/2000/svg" color="danger" width="24" height="24" viewBox="0 0 24 24"><path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" /></svg>
                );
            case 3:
                return (
                    <Typography> <Tooltip title="Requirement"><i className="fa fa-exclamation-triangle fa-2x" style={{ color: '#ff4d4d' }}></i></Tooltip></Typography>
                );
            case 4:
                return (
                    <Typography> <Tooltip title="Approval"><i className="far fa-square fa-2x" style={{ color: '#ffff4d' }}></i></Tooltip></Typography>
                );
            case 5:
                return (
                    <Typography> <Tooltip title="Decision"><i className="fa fa-question-circle fa-2x" style={{ color: '#d2a679' }}></i></Tooltip></Typography>
                    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>
                );
            case 6:
                return (
                    <Typography> <Tooltip title="Approved"><i className="fa fa-check-square fa-2x" color="primary" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
                );
            case 7:
                return (
                    <Typography> <Tooltip title="Decided"><i className="fa fa-check-square fa-2x" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
                );
            case 8:
                return (
                    <Typography> <Tooltip title="Resolved"><i className="fa fa-check-square fa-2x" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
                );
            case 9:
                return (
                    <Typography> <Tooltip title="Not Approved"><i className="fa fa-times-circle fa-2x" style={{ color: '#ff4d4d' }}></i></Tooltip></Typography>
                );
            default:
                return (null);
        }
    }

    renderTheString(note) {
        if (note.mentions.length > 0) { //checking for mentions
            //console.log(note.mentions);
            //To remove the user Id from note content
            let a = note.note.replace(/##[a-z0-9]+/ig, "");
            //console.log(a);
            // To get the mention(user) ID
            // let userId = note.note.match(/##[a-z0-9]+/ig)[0].replace();
            // To remove the special character from note content expect for @ character
            let b = a.replace(/[^a-zA-Z0-9@. ]/g, "");
            //console.log(b);
            let replacedText;
            // Match @-mentions
            replacedText = reactStringReplace(b, /@([\w-\.]+@[\w-\.]+\.\w+)/g, (match, i) => (
                <a style={{ color: '#f50057' }} component="button" key={match + i} ><Profile name={"@" + match} username={match} isMention={true} /></a>
            ));

            return replacedText;
        } else
            return note.note;
    }

    render() {
        const vertical = 'top'; // for snackbar
        const horizontal = 'left' // for snackbar
        const { classes, users } = this.props;

        const mentionDataFormat = users.map(({ _id, username }) => ({ id: _id, display: username })); // Formatting according to mentions plugin data format
        return (
            <div>
                <div style={{ background: 'white' }}>
                    {this.renderGrid()}
                    {this.renderAccordian()}
                </div>
                <Snackbar
                    style={{ marginTop: 50 }}
                    key="sentMessage"
                    anchorOrigin={{ vertical, horizontal }}
                    TransitionComponent={this.TransitionRight}
                    open={this.state.openConfirm}
                    autoHideDuration={1000}
                    onClose={this.handleCloseSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={<span id="message-id">Saved....</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="primary"
                            className="snackX"
                            onClick={this.handleCloseSnack}
                        >
                        <i class="far fa-times-circle white-icon"></i>
                        </IconButton>
                    ]}
                />
                <LocationNote
                    onClose={this.handleClose}
                    open={this.state.openLocationDialog}
                    locationType={this.state.locationType}
                    productId={this.state.productId}
                    taskId={this.state.taskId}
                    users={this.props.users}
                    storeLocationsValue={this.storeLocations}
                />
            </div>

        )
    };
}

FormPage.propTypes = {
    form: PropTypes.object,
    isDialog: PropTypes.bool,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    data: PropTypes.object,
    onClose: PropTypes.func,
    displayTitle: PropTypes.string,
    errorMessage: PropTypes.string
};

export default withStyles({ withTheme: true })(FormPage);
