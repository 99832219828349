import React, { Component } from 'react';
import PropTypes from 'prop-types';


import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle'
import DotMenuIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';



import '../../../css/ProductRunner.scss';

const boardColors = ['board-color0', 'board-color1', 'board-color2', 'board-color3'];
const boardColorCount = boardColors.length;

class BoardTop extends Component {
    constructor(props) {
        super(props);
        let ci = 0;
        if (props.index) {
            ci = props.index;
        }

        this.state = {
            menuOpen: false,
            menuSelection: null,
            searchOpen: false,
            searchTerm: '',
            boardName: '',
            boardColor: boardColors[ci],
        };
    }


    // *******************************************
    //
    //    gadgets 
    //
    // *******************************************

    openMenu = () => {
        this.setState({ menuOpen: true });
    };

    closeMenu = () => {
        this.setState({ menuOpen: false });
    };

    doMenuItem = (index) => {
        this.props.onMenu(index, this.state.menuSelection);
    }

    onClickAdd = (index) => {
        this.props.onAdd(index);
    }

    openSearch = (index) => {
        this.setState({ searchOpen: true });
    }

    closeSearch = (index) => {
        this.setState({ searchOpen: false });
    }

    doSearch = (index) => {
        this.props.onSearch(index, this.state.searchTerm);
    }


    render() {
        const props = this.props;
        return (
            <span>
                <div className={`board-name`}>
                    {props.title}
                    <div className={`board-gadgets`}>
                        {this.props.onAdd ?
                            <IconButton onClick={() => this.onClickAdd(`${this.props.index}`)} className={'board-gadget'} color='inherit'>
                                <AddIcon />
                            </IconButton> : null
                        }
                        {this.props.onSearch ?
                            <IconButton onClick={() => this.openSearch(`${this.props.index}`)} className={'board-gadget'} color='inherit'>
                                <SearchIcon />
                            </IconButton> : null
                        }
                        {(this.props.onMenu && this.props.menu) ?
                            <IconButton onClick={() => this.openMenu(`${this.props.index}`)} className={'board-gadget'} color='inherit'>
                                <DotMenuIcon />
                            </IconButton> : null
                        }
                    </div>
                    <div className={'board-subtitle'}>{props.subTitle}</div>
                </div>
                <div className={`board-bar ${this.state.boardColor}`}></div>
            </span>
        );
    }
}

BoardTop.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    index: PropTypes.number,
    onAdd: PropTypes.func,
    onSearch: PropTypes.func,
    onMenu: PropTypes.func,
    menu: PropTypes.array
};
export default BoardTop;