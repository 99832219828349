import React from 'react';
import PropTypes from 'prop-types';

const LoadingIndicator = (props) => (
    <div>
        <div style={{margin:'2em auto', width: 200, textAlign:'center'}}>{props.message ? <span className={props.classes}> {props.message} </span> : null}
        </div>
        <div
            className="standard-loading-indicator"
            style={props.style || undefined}
        >
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

LoadingIndicator.propTypes = {
    message: PropTypes.string,
    classes: PropTypes.object,
    style: PropTypes.object
};
export default LoadingIndicator;