import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import api from '../../../shared/api';
import '../../../css/ProductRunner.scss';
import HomeNavigation from '../../../components/User/Navigation/PRNavigation';
import AppData from '../../../shared/AppData';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import FormDialog from '../../../components/FormDialog/FormDialog';
import BoardTop from '../../../components/User/Board/NoteBoardTop';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Canvas from '../../../components/User/Assets/Canvas';
import Document from '../../../components/User/Assets/Document';

// To create list item
import Note from '../../../components/User/Note/Note';
import ScrollToTop from 'react-scroll-up';
import Img from 'react-image';
const scrollTop = require('../../../img/pr/images/scrollTop.png');

const styles = theme => ({
    root: {
        backgroundColor: 'white',
        marginTop: '10px'
    },
    listLi: {
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    avatar: {
        margin: 10,
    },
    button: {
        margin: theme.spacing.unit,
    },
});
const options = [
    { label: 'All', key: 1, value: false, disabled: false },
    { label: 'Active', key: 2, value: true, disabled: false },
    { label: 'Archived', key: 3, value: false, disabled: false },
    { label: 'Assigned to me', key: 4, value: false, disabled: false }
];
class Tasks extends Component {
    constructor(props) {
        super(props);
        const testType = props.location.search.split('=')[1];

        this.state = {
            testType,
            loading: false,
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle: "", // this is what the user sees (could be blank)
            note: [],
            users: [],
            filterSelected: [{ label: 'Active', key: 2, value: true }], //2 = Active notes
            filter: false,
        };
    }
    state = {
        anchorEl: null,
    };

    componentWillMount() {
        this.loadUser();
    }

    componentDidMount() {

    }

    // loadContent = async () => {
    //     // To get all the note for a task
    //     try {
    //         const notes = await api.get('/note/all');
    //         this.setState(
    //             {
    //                 notes: notes.data.data.data,
    //                 // showNotes: true,
    //             },
    //             () => {
    //                 // do this when setState() is finished...

    //             }
    //         );
    //     } catch (e) {
    //         // do this if api.get() throws an error;
    //         alert(e);
    //     }
    // };

    componentWillReceiveProps(nextProps) {
    }

    // *******************************************
    //
    //    for form dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    closeSignIn = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = async (formName, response) => {
        if (formName == 'Note' && response.status === 'OK') {
            const { formData } = response;
            let err = '';
            if (err !== '') {
                err = "Oh no, not quite right: " + err;
                this.setState({ errorMessage: err });
            } else {
                // ********* To add a note *********
                try {
                    const note = await api.post('/note/create', {
                        subject: response.formData.Subject,
                        note: response.formData.Note,
                        type: response.formData.Type,
                        author_id: JSON.parse(sessionStorage.getItem("pr_user")).id,
                        assignedTo: response.formData.Assign
                    });

                    if (note.data.data.success) {
                        let currentNotes = [];
                        this.setState({ formDialogOpen: false });
                        currentNotes = [note.data.data.data, ...currentNotes];
                        this.setState(
                            { note: currentNotes },
                            () => {
                                //                 // do this when setState() is finished...
                                this.setState({ note: [] });
                            }
                        );
                        // this.refs.child.showReply(note.data.data.data._id);
                        response.formData.Subject = '';
                        response.formData.Note = '';
                    } else {
                        this.setState({ errorMessage: note.data.data.errMsg });
                    }
                } catch (e) {
                    // do this if api.get() throws an error;
                    alert(e);
                }
            }
        } else {
            this.setState({ formDialogOpen: false });
        }
    }

    // *******************************************
    //
    //    for "gadgets" on top of columns
    //
    // *******************************************
    addNewNote = (columnNumber) => {
        // To load user for assigning 
        this.loadUser();
        this.openDialog('Note', 'Add a Note');

    }

    searchColumn = (columnNumber, searchTerm) => {
        alert(searchTerm);
    }

    executeMenuSelection = (columnNumber, menuSelection) => {
        alert(menuSelection);
    }
    // To all the user for the product 
    loadUser = async (noteId = "false") => {
        // To get all the note for a task
        try {
            const users = await api.get('/note/users/all/');
            this.setState(
                {
                    users: users.data.data.data,
                },
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }
    menu = (menuSelection) => {
        let currentSelectedMenu = [];
        let newFilter = menuSelection.filter(x => x.value == true);
        if (newFilter.length == 0) {
            currentSelectedMenu = this.state.filterSelected;
        } else {
            currentSelectedMenu = menuSelection;
        }
        this.setState(
            { filterSelected: currentSelectedMenu, filter: true },
            () => {
                this.setState({ filter: false });
            }
        );
    }
    render() {
        const { classes } = this.props;
        const { anchorEl, note } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div>
                {this.state.loading ? (
                    <LoadingIndicator
                        message={`Loading your products...`}
                    />
                ) : (
                        <div style={{ padding: 1 }}>
                            <h2>Task</h2>
                            <div className={`flex-grow`}>
                                <Grid container spacing={32}>
                                    <Grid item xs={12} sm={7} md={8} lg={9}>
                                       <div style={{width:'100%', minHeight:'800px', fontSize:24, color:'black'}}>
                                       {!this.state.testType  ?
                                          <Canvas name={`LeanProduct`} />
                                          :
                                       <Document 
                                        name={`TestDoc`}
                                        />
                                       }
                                       </div>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={4} lg={3}>
                                        <BoardTop
                                            title={'Notes'}
                                            subTitle={'Have a discussion here...'}
                                            index={1}
                                            onAdd={this.addNewNote}
                                            onSearch={this.searchColumn}
                                            onMenu={this.menu}
                                            menu={options}
                                        />
                                        <Note newNote={note} users={this.state.users} onUser={this.loadUser} onFilter={this.state.filterSelected} filter={this.state.filter} />
                                        <ScrollToTop showUnder={160}>
                                            {/* <Button style={{ right: 20, bottom: -10, position: 'absolute' }} variant="contained" size="small" color="primary" className={classes.button}>Top</Button> */}
                                            <Img src={scrollTop} style={{ width: 50, height: 50 }} />
                                        </ScrollToTop>
                                        <Button style={{ right: 20, bottom: -10, position: 'absolute' }} variant="contained" size="small" onClick={this.addNewNote} color="primary" className={classes.button}>Add Note</Button>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                    )}
                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    onClose={this.formClosed}
                    users={this.state.users}
                />
            </div>
        );
    }
}
Tasks.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Tasks
