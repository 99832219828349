import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Grid, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import api from '../../../shared/api';

import HomeNavigation from '../../../components/User/Navigation/PRNavigation';
import AppData from '../../../shared/AppData';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import FormDialog from '../../../components/FormDialog/FormDialog';
import Canvas from '../../../components/User/Assets/Canvas';
import FormPage from '../../../components/User/Assets/FormPage';
//import BoardTop from '../../../components/User/Board/BoardTop';
import BoardTop from '../../../components/User/Board/NoteBoardTop';
import TaskList from '../../../components/User/Assets/TaskList';

import '../../../css/ProductRunner.scss';

// To create list item
import Note from '../../../components/User/Note/Note';

const options = [
    { label: 'All', key: 1, value: false, disabled: false },
    { label: 'Active', key: 2, value: true, disabled: false },
    { label: 'Archived', key: 3, value: false, disabled: false },
    { label: 'Assigned to me', key: 4, value: false, disabled: false }
];

const styles = theme => ({
    root: {
        backgroundColor: 'white',
        marginTop: '10px'
    },
    listLi: {
        paddingLeft: '0px',
        paddingRight: '0px'
    },
    avatar: {
        margin: 10,
    },
    button: {
        margin: theme.spacing.unit,
    },
});

class Tasks extends Component {
    constructor(props) {
        super(props);

        const isTopMasterList = props.location.pathname.includes(`/product`);
        let productId = null;
        let taskId = null;
        let parentTask = null;
        let subTask = null;
        let assetName = null;
        let assetType = null;
        if (isTopMasterList) {
            const params = props.location.search.split('=');
            productId = taskId = params[1];
        } else {
            // bust apart the params and look for the task id...
            const search = props.location.search.replace('?', '');
            let params = search.split('&');
            if (params.length === 0) {
                params = search;
            }
            params.forEach(element => {
                const pair = element.split('=');
                switch (pair[0]) {
                    case 'id':
                        taskId = pair[1];
                        break;
                    case 'parentTask':
                        parentTask = pair[1];
                        break;
                    case 'subTask':
                        subTask = pair[1];
                        break;
                    case 'type':
                        assetType = pair[1];
                        break;
                    case 'name':
                        assetName = pair[1];
                        break;
                    default:
                        break;
                }
            });
        }

        this.state = {
            loading: true,
            loadingMessage: 'Loading task...',
            productId,
            taskId,
            parentTask,
            subTask,
            assetName,
            assetType,
            isTopMasterList,
            pageTitle: isTopMasterList ? 'Master Task List' : assetName,
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle: "", // this is what the user sees (could be blank)
            navNode: props.navRef,
            note: [],
            users: [],
            filterSelected: [{ label: 'Active', key: 2, value: true }], //2 = Active notes
            filter: false,
        };
    }

    componentWillMount() {
        // first see if there is a task...
        if (this.state.productId) {
            // then get the task
            this.loadProduct();
            this.loadUser();
        } else {
            // will need to get the product id when we load the task below
            this.loadTask();
            this.loadUser();
        }
        this.state.navNode.setState({ productName: 'MY Products', bcolor: 'white', color: 'black'});

    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {

            const isTopMasterList = this.props.location.pathname.includes(`/product`);
            let productId = null;
            let taskId = null;
            let subTask = null;
            let assetName = null;
            let assetType = null;
            if (isTopMasterList) {
                const params = this.props.location.search.split('=');
                productId = taskId = params[1];
            } else {
                // bust apart the params and look for the task id...
                const search = this.props.location.search.replace('?', '');
                let params = search.split('&');
                if (params.length === 0) {
                    params = search;
                }
                params.forEach(element => {
                    const pair = element.split('=');
                    switch (pair[0]) {
                        case 'id':
                            taskId = pair[1];
                            break;
                        case 'subTask':
                            subTask = pair[1];
                            break;
                        case 'type':
                            assetType = pair[1];
                            break;
                        case 'name':
                            assetName = pair[1];
                            break;
                        default:
                            break;
                    }
                });
            }

            this.setState({
                loading: true,
                loadingMessage: 'Loading task...',
                productId,
                taskId,
                subTask,
                assetName,
                assetType,
                isTopMasterList,
                productName: '',
                pageTitle: isTopMasterList ? 'Master Task List' : assetName,
                formDialogOpen: false,
                formName: "",   // this describes the form to the SYSTEM
                formDisplayTitle: "" // this is what the user sees (could be blank)
            }, () => {
                // do this when setState() is finished...
                if (this.state.isTopMasterList) {
                    // then get the task
                    this.loadProduct();
                    this.loadUser();
                } else {
                    // will need to get the product id when we load the task below
                    this.loadTask();
                    this.loadUser();
                }

            });
        }
    }

    componentDidMount() {

    }

    loadTask = async () => {
        try {

            //    const task = await api.get(`/task/${this.state.taskId}`);

            const task = await api.get(`/task/getWithOrCreateAsset`, {
                params: {
                    assetName: this.state.assetName,    // in case there is no asset yet
                    assetType: this.state.assetType,    // in case there is no asset yet
                    parentTask: this.state.parentTask,
                    taskId: this.state.taskId,
                    subTask: this.state.subTask,
                }
            });
            const t = task;

            this.setState(
                {
                    task: task.data.data.task,
                    parentTask: task.data.data.parentList,
                    productName: task.data.data.task.product.name,
                    productId: task.data.data.task.product._id,
                    taskId: task.data.data.task._id,
                    pageTitle: task.data.data.task.name,
                    loading: false
                },
                () => {
                    // do this when setState() is finished...
                    // grab NOTES HERE FOR THE CURRENT TASK: use the just set this.state.task._id for the taskId and this.state.product._id 
                    this.state.navNode.setState({ productName: this.state.productName, productId: this.state.productId, color: 'black' });
                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }

    loadProduct = async () => {

        try {
            this.setState({ loading: true });
            const productResult = await api.get(`/product/${this.state.productId}`);

            const product = productResult.data.data.product;
            const phase = product.prPhase ? product.prPhase : 'idea';
            this.setState(
                {
                    product,
                    task: productResult.data.data.task,
                    list: productResult.data.data.list,
                    phase,
                    productId: product._id,
                    productName: product.name,
                    taskId: productResult.data.data.task._id,
                    loading: false
                },
                () => {
                    // do this when setState() is finished...
                    // grab NOTES HERE FOR THE PRODUCT / MASTER LIST: use the just set this.state.task._id for the taskId and this.state.product._id 
                    this.state.navNode.setState({ productName: this.state.productName, productId: this.state.productId, color: 'black' });

                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    };

    componentWillReceiveProps(nextProps) {
        const { search } = nextProps.location;
        const params = search.split('=');
        const taskId = params[1];
        const isTopMasterList = nextProps.location.pathname.includes(`/product`);
        const productId = isTopMasterList ? taskId : null;

    }

    // *******************************************
    //
    //    for form dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    closeSignIn = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = async (formName, response) => {
        if (formName == 'Note' && response.status === 'OK') {
            const { formData } = response;
            let err = '';
            if (err !== '') {
                err = "Oh no, not quite right: " + err;
                this.setState({ errorMessage: err });
            } else {
                // ********* To add a note *********
                try {
                    const note = await api.post('/note/create', {
                        productId: this.state.productId,
                        taskId: this.state.taskId,
                        subject: response.formData.Subject,
                        note: response.formData.Note,
                        type: response.formData.Type,
                        author_id: JSON.parse(sessionStorage.getItem("pr_user")).id,
                        assignedTo: response.formData.Assign
                    });

                    if (note.data.data.success) {
                        let currentNotes = [];
                        this.setState({ formDialogOpen: false });
                        currentNotes = [note.data.data.data, ...currentNotes];
                        this.setState(
                            { note: currentNotes },
                            () => {
                                //                 // do this when setState() is finished...
                                this.setState({ note: [] });
                            }
                        );
                        // this.refs.child.showReply(note.data.data.data._id);
                        response.formData.Subject = '';
                        response.formData.Note = '';
                    } else {
                        this.setState({ errorMessage: note.data.data.errMsg });
                    }
                } catch (e) {
                    // do this if api.get() throws an error;
                    alert(e);
                }
            }
        } else {
            this.setState({ formDialogOpen: false });
        }
    }

    // *******************************************
    //
    //    for "gadgets" on top of columns
    //
    // *******************************************
    addNewNote = (columnNumber) => {
        // To load user for assigning 
        this.loadUser();
        this.openDialog('Note', 'Add a Note');
    }

    searchColumn = (columnNumber, searchTerm) => {
        alert(searchTerm);
    }

    executeMenuSelection = (columnNumber, menuSelection) => {
        alert(menuSelection);
    }

    // To all the user for the product 
    loadUser = async (noteId = "false") => {
        // To get all the note for a task
        try {
            const users = await api.get('/note/users/all/');
            this.setState(
                {
                    users: users.data.data.data,
                },
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }

    menu = (menuSelection) => {
        let currentSelectedMenu = [];
        let newFilter = menuSelection.filter(x => x.value == true);
        if (newFilter.length == 0) {
            currentSelectedMenu = this.state.filterSelected;
        } else {
            currentSelectedMenu = newFilter;
        }
        this.setState(
            { filterSelected: currentSelectedMenu, filter: true },
            () => {
                this.setState({ filter: false });
            }
        );
    }

    render() {
        const { classes } = this.props;
        const { note } = this.state;
        return (
            <div>
                {this.state.loading ? (
                    <LoadingIndicator
                        message={this.state.loadingMessage}
                    />
                ) : (
                        <div style={{ padding: 1 }}>
                            {/*}
                            <div className='productName'>
                                <Link to={`/product?id=${this.state.productId}`}>
                                    {this.state.productName} (Idea Phase)
                                </Link>
                            </div>
                */}
                    <div style={{height:16, width: '100%', backgroundColor: 'yellow', color:'black', paddingLeft: 12, paddingTop: 3, fontSize: 12, marginTop: 36}}>IDEA PHASE</div>
                    <div style={{height:16, width: '100%', backgroundColor: 'purple', color:'white', paddingLeft: 12, paddingTop: 3, fontSize: 12}}>ENVISION PHASE</div>

                            <div className={`flex-grow`}>
                                <Grid container spacing={32}>
                                    <Grid item xs={12} sm={7} md={8} lg={9}>
                                        <div style={{ fontSize: 22, textAlign: 'center', margin: 24, marginTop: 16 }}>
                                            <IconButton style={{ float: 'left', marginLeft: 24 }}>
                                                <ChevronLeftIcon
                                                />
                                            </IconButton >

                                            {this.state.pageTitle}
                                            <IconButton style={{ float: 'right', marginRight: 24 }}>
                                                <ChevronRightIcon
                                                />
                                            </IconButton >
                                        </div>
                                        <div className={'task-status'}>
                                            Assigned to: me  | Due: in 2 days | Status: Needs Approval 
                                                <Button size='small' color='primary' style={{ margin: '0px 8px' }}>Share</Button>
                                                <Button size='small' color='primary' style={{ margin: '0px 8px' }}>Follow</Button>
                                        </div>
                                        <div style={{ marginTop: 22 }} >
                                            {(() => {
                                                switch (this.state.task.assetType) {
                                                    case 'TaskList':
                                                        return <TaskList taskId={this.state.task._id} asset={this.state.task.assetTemplate} />;
                                                   // case 'List':
                                                   //     return <List asset={this.state.task.asset} />;
                                                    
                                                   case 'Canvas':
                                                        return <Canvas users={this.state.users} addNote={this.addNewNote} canvas={this.state.task} />;
                                                   case 'Form':
                                                        return <FormPage users={this.state.users} addNote={this.addNewNote} form={this.state.task} />;
                                                    default:
                                                        return null;
                                                }
                                            })()}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={4} lg={3}>
                                        <div style={{ marginTop: 2 }}>
                                            <BoardTop
                                                title={'Notes'}
                                                subTitle={'Have a discussion here...'}
                                                index={1}
                                                onAdd={this.addNewNote}
                                                onSearch={this.searchColumn}
                                                onMenu={this.menu}
                                                menu={options}
                                            />
                                            <Note
                                                newNote={note}
                                                users={this.state.users}
                                                onUser={this.loadUser}
                                                onFilter={this.state.filterSelected}
                                                filter={this.state.filter}
                                                addNote={this.addNewNote}
                                                productId={this.state.productId}
                                                taskId={this.state.taskId}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                    )}
                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    onClose={this.formClosed}
                    users={this.state.users}
                />
            </div>
        );
    }
}

export default Tasks;
