import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import merge from 'lodash.merge';

// *********** Material UI *************
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent, FormControlLabel, Tooltip, withStyles } from '@material-ui/core';
import { Button, TextField, Checkbox, FormControl, Select, InputLabel, FormHelperText, Input, MenuItem, Snackbar, IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';


import UserAvatar from '../../../components/User/UserAvatar/UserAvatar';
import 'font-awesome/css/font-awesome.min.css';
import Profile from '../Note/Profile';
import reactStringReplace from 'react-string-replace';
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import CardHeader from '@material-ui/core/CardHeader';

// **********  PR Components ****************
import api from '../../../shared/api';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import AppData from '../../../shared/AppData';
import { EINTR } from 'constants';
import LocationNote from '../Note/LocationNote';
import './Asset.scss';

// ******** WYSIWYG EDITOR *********************
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { blue } from '@material-ui/core/colors';
import { async } from 'q';

// ************* Test canvases *****************
const LeanProduct = {
    name: 'leanProduct',
    id: "12345",
    validate: false,
    rows: [
        // row 1
        {
            rowHeight: 3,       // heights are out of 12 possible, with 12 being the full height of the canvas
            columns: [
                {
                    // column 1
                    entries: [
                        { title: 'Field 1', type: 'textarea', fieldName: 'field1', prompt: 'This is the instructions for field 1', placeholder: 'Your email', required: true },
                        { title: 'Field 2', type: 'textarea', fieldName: 'field2', prompt: 'This is the instructions for field 2', required: true },
                    ]
                },
                {
                    // column 
                    entries: [
                        { title: 'Column 2.0', fieldName: "column2.0", prompt: 'This is the instructions for colum 2.0', required: true },
                        { title: 'Column 2.1', fieldName: "column2.1", prompt: 'This is the instructions for column 2.1', required: true },
                        { title: 'Column 2.2', fieldName: "column2.2", prompt: 'This is the instructions for column 2.2', required: true },
                    ]
                },
            ]
        },
        // row 2
        {
            rowHeight: 8,
            columns: [
                {
                    // column 1
                    entries: [
                        { title: `f2.0`, type: 'text', fieldName: `field 2.0`, prompt: 'This is the instructions for field 2.0', placeholder: 'Your email', required: true },
                        { title: `f2.1`, type: 'password', fieldName: `field 2.1`, prompt: 'This is the instructions for field 2.1', required: true },
                    ]
                },
                {
                    // column 2
                    entries: [
                        { title: `The Big Idea`, type: 'textarea', fieldName: 'bigIdea', prompt: `Instructions for What's the BIG IDEA?`, placeholder: 'Just a sentence or two please!', required: true },
                        { title: `Who's it for?`, type: 'text', fieldName: 'whoIsItFor', prompt: `instructions for Who's it for?`, placeholder: 'List your target audience(s)', required: true },
                        { title: `What's the Change?`, type: 'text', fieldName: 'theChange', prompt: `instructions for What's the change?`, placeholder: 'What change will this product make for the users?', required: true },
                    ]
                },
                {
                    // column 3
                    entries: [
                        { type: 'text', fieldName: `row2col0`, prompt: 'This is the instructions for row 2 col 0', placeholder: 'Your email', required: true },
                        { type: 'password', fieldName: `row2col1`, prompt: 'This is the instructions for row 2 col 1', required: true },
                    ]
                }
            ]
        },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Sign In',
        skip: 'DNU'         // DNU = Do Not Use
    }
};


const sampleCanvas = {
    canvasName: 'SampleForm',
    isTypeForm: false,
    canvas: [
        { type: 'text', prompt: 'Field 1', placeholder: 'Enter...', required: true },
        { type: 'text', prompt: 'Field 2', placeholder: '(optional)', required: false },
        { type: 'text', prompt: 'Field 3', placeholder: 'Required', required: true },
        { type: 'text', prompt: 'Field 4', placeholder: '', required: true },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Save',
        skip: 'Skip'         // DNU = Do Not Use
    }
};

// ******************* CSS CLASSES ***********************
const styles = theme => ({
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: '#555',
        fontStyle: 'italic',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        textTransform: 'uppercase'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    card: {
        borderRadius: '5px',
        border: '1px solid',
        height: '80%',
        overflow: 'scroll',
        position: 'relative'
    },
    cardList: {
        float: 'left',
        width: '250px',
        height: '200px',
        margin: '2em',
        border: '4px solid',
        borderBottomColor: 'white',
        borderTopColor: 'white',
        borderLeftColor: 'white',
        borderRightColor: 'white',
        backgroundColor: 'white',
        color: 'black',
        textAlign: 'left',
        webkitBoxShadow: '0px 3px 5px 0px rgba(0,0,0,0.38)',
        mozBoxShadow: '0px 3px 5px 0px rgba(0,0,0,0.38)',
        boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.38)',
        overflow: 'scroll'
    },
})

// ******************* Canvas Component ******************

class Canvas extends Component {
    constructor(props) {
        super(props);
        const responses = (props.canvas && props.canvas.assetData) ? props.canvas.assetData : {};
        const ctemplate = (props.canvas && props.canvas.assetTemplate) ? props.canvas.assetTemplate : LeanProduct;
        const cn = (props.canvas) ? props.canvas.name : '';

        this.state = {
            task: props.task,
            canvas: props.canvas,
            canvasHeight: 800,
            updated: false,
            loading: false,
            name: cn,
            template: ctemplate,
            firstTime: true,
            errorMessage: '',
            hasData: false,
            responses,
            currentQuestion: 0,
            canvasXPos: 0,
            openConfirm: false,
            editorState: EditorState.createEmpty(),
            openLocationDialog: false,
            locationType: null,
            productId: this.props.canvas.product,
            taskId: this.props.canvas._id,
            expanded: null,
            locationTypesArr: [],
            sectionWiseNotes: [],
            locationNotes: [],
            locationFields: [],
        };
        this.onChangeText = this.onChangeText.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.required = {};
        this.changed = {};
        this.original = {};
    }

    static cancelButton = 'Cancel';
    static skipButton = 'Skip';
    static OKButton = 'OK';
    static totalQuestions = 0;


    componentWillMount() {
        const { template } = this.state;
        if (!template) {
            return;
        }
        let res = [];
        let locationFieldsRes = [];
        // ********************* calculate column widths for each row **************
        const rowHeight = Math.floor(this.state.canvasHeight / template.rows.length);
        let totalHeight = 0;
        let entryCount = 1;
        template.rows.map((nextRow, index) => {

            if (!nextRow.rowHeight) {
                nextRow.rowHeight = rowHeight;
            } else {
                nextRow.rowHeight = Math.floor(this.state.canvasHeight * nextRow.rowHeight / 12);
                // nextRow.rowHeight = rowHeight;
            }

            if (totalHeight + nextRow.rowHeight > this.state.canvasHeight || index === (template.rows.length - 1)) {
                nextRow.rowHeight = this.state.canvasHeight - totalHeight;
            }
            totalHeight += nextRow.rowHeight;

            nextRow.colWidth = Math.floor(12 / nextRow.columns.length);
            nextRow.columns.map((nextColumn) => {
                let rh = 0;
                const hasSub = nextColumn.entries[0].type === 'hasSub';
                nextColumn.entries.map((entry, index) => {
                    if (hasSub) {
                        if (index === 0) {
                            entry.height = nextRow.rowHeight - 200;
                        } else {
                            entry.height = 200;
                        }
                    } else {
                        entry.height = Math.floor((nextRow.rowHeight / nextColumn.entries.length) - 2);
                    }
                    if (index === (nextColumn.entries - 1)) {
                        entry.height = nextRow.rowHeight - rh;
                    } else {
                        rh += entry.height;
                    }
                    if (!entry.title) {
                        entry.title = `Item ` + entryCount;
                    }
                    entryCount++;
                    const field = entry.fieldName ? entry.fieldName : entry.prompt;
                    this.changed[field] = false;
                    this.original[field] = '';

                    // To get the current notes count of the each locations

                    api.get('/note/get/locationCount/' + this.state.productId + '/' + this.state.taskId + '/' + entry.fieldName).then((result) => {
                        if (result.data.data.data.count > 0) {
                            res.push({
                                locationType: entry.fieldName,
                                locationTypeNotes: result.data.data.data.notes,
                                locationTypeCount: result.data.data.data.count
                            })
                            locationFieldsRes.push(entry.fieldName)
                            this.setState({
                                locationFields: locationFieldsRes,
                                locationNotes: res,
                            })
                            this.storeLocations();
                        }
                    })
                    if (this.state.locationTypesArr.includes(entry.fieldName) === false) {
                        this.state.locationTypesArr.push(entry.fieldName);
                    }
                })
            })
        })

    }

    componentDidMount() {
    }

    storeLocations = (locationArr) => {
        if (locationArr != undefined) {
            if (locationArr[0].isAdd == true) {
                if (this.state.locationFields.includes(locationArr[0].locationType)) {
                    this.setState({
                        locationFields: this.state.locationFields
                    })
                } else {
                    let res = this.state.locationFields;
                    res.push(locationArr[0].locationType);
                    this.setState({
                        locationFields: res
                    })
                }
            }
            if (locationArr[0].isDelete == true) {
                this.setState({
                    locationFields: this.state.locationFields.filter(function (item) {
                        return item !== locationArr[0].locationType
                    })
                })
            }
        }
    }

    /*
    loadFromDB = async (canvasName) => {
        let template = null;        // set to null to 

        let firstTime = false;
        const userId = AppData.get('userId');

        try {
            const template = await api.get('/canvas/getTemplate');
            this.setState(
                {
                    canvasTemplate: template.data.data.data,
                    loading: false
                },
                () => {
                    // do this when setState() is finished...
                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
            template = LeanProduct;
            this.setState({ canvasTemplate: sampleCanvas, loading: false });
        }

        return (template);
    }
    */

    componentWillReceiveProps(nextProps) {
        this.setState({
            productId: nextProps.canvas.product._id,
            taskId: nextProps.canvas._id,
            openLocationDialog: false,
        })
    }

    // *******************************************
    //
    //    canvas dialog 
    //
    // *******************************************
    TransitionUp(props) {
        return <Slide direction="up" {...props} />;
    }

    TransitionRight(props) {
        return <Slide {...props} direction="right" />;
    }

    openMe = () => {
        this.setState({ iAmOpen: true });
    };

    onChangeText = (e) => {
        const id = e.target.id;
        const text = e.target.value;
        let value = {};
        value[id] = text;
        const newState = merge({}, this.state.responses, value);
        this.setState({ responses: newState });
        this.changed[id] = true;
    };

    onBlur = (e) => {
        const id = e.target.id;
        if (this.changed[id] === true) {
            this.setState({ openConfirm: true });
            this.changed[id] = false;
        }
    }

    onHandleCheckbox = (e) => {
        const fieldName = e.target.id;
        const newState = merge({}, this.state.responses, { [fieldName]: e.target.checked });
        this.setState({ responses: newState });
    };

    onHandlePickList = (event, fieldName) => {
        const newState = merge({}, this.state.responses, { [fieldName]: event.target.value });
        this.setState({ responses: newState });
    }

    closeMe = (button) => {
        // button is one of: 'Cancel', 'Skip', 'OK'

        let valid = true;
        if (button === 'OK' && this.state.template.validate === true) {
            // then need to validate the canvas.
            for (const key of Object.keys(this.required)) {

                const value = this.state.responses[key] ? this.state.responses[key] : null;
                if (!value || value === '' || value === null) {
                    valid = false;
                }
            }

            if (!valid) {
                this.setState({ errorMessage: `Sorry, please complete this canvas' required fields` });
                return;
            }
        }

        let response = { action: 'close', status: button, data: this.state.responses };
        if (this.props.onClose) {
            this.setState({ loading: true });
            this.props.onClose(this.props.name, response);
        }
    };

    onKeyDown = (e, type) => {
        const { keyCode } = e;
        if (this.forwardKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf' || keyCode !== 13) {
                this.nextQuestion(1);
            }
        }
        if (this.backKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf') {
                this.nextQuestion(-1);
            }
        }
        return null;
    }

    handleCloseSnack = () => {
        this.setState({ openConfirm: false });
    };

    // ********************************************
    //
    // deal with Editor
    // 
    // ********************************************
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    // ************************
    //
    //  openLocationDialog  
    //
    // ************************

    openLocDialog = async (locationType) => {
        this.setState({
            openLocationDialog: true,
            locationType: locationType
        })
    }

    handleClose = () => {
        this.setState({
            openLocationDialog: false,
        });
    };

    // ************************************************
    //
    // render canvas item logic 
    //
    // ************************************************
    renderFormItem = (item) => {
        const { prompt, placeholder, required, fieldName, label, list } = item;
        const field = fieldName ? fieldName : prompt;
        if (required) {
            this.required[field] = type;
        }

        const questionClass = this.state.isWizard ? 'fd-wizardPanel' : 'fd-wizardQuestion';
        const type = 'text';
        switch (type) {
            case 'row':
                return (
                    <div style={{ height: 40, background: 'gray' }}>
                        {prompt}
                    </div>
                );

            case 'password':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onKeyDown={this.onKeyDown}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            fullWidth
                        />
                    </div>
                );
            case 'text':
                return (
                    <div>
                        {/*}
                                        <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onKeyDown={(event) => this.onKeyDown(event, 'tf')}
                            placeholder={placeholder}
                            margin="normal"
                            fullWidth
                        />
                */}
                        <textarea
                            id={field}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            onBlur={this.onBlur}
                            style={{ resize: `none`, width: `100%`, height: item.height - 29, border: `none`, fontSize: 14, padding: 8, boxSizing: `border-box` }}
                            placeholder={placeholder}>
                        </textarea>
                    </div>
                );
            case 'textarea':
                return (
                    <div className={questionClass}>
                        <TextField
                            required={required}
                            id={field}
                            label={prompt}
                            value={this.state.responses[field]}
                            onChange={this.onChangeText}
                            placeholder={placeholder}
                            margin="normal"
                            // variant="outlined"
                            multiline
                            rowsMax="4"
                            fullWidth
                        />
                    </div>
                )

            case 'singlecheck':
                return (
                    <div className={questionClass}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={fieldName}
                                    required={required}
                                    onChange={this.onHandleCheckbox}
                                    value={this.state.responses[fieldName]}
                                />
                            }
                            label={label}
                        />
                    </div>
                )
            case 'picklist':
                return (
                    <div className={questionClass}>
                        <FormControl style={{ margin: 30, minWidth: 200 }} >

                            <Select style={{ marginTop: 20 }}
                                id={fieldName}
                                name={fieldName}
                                value={this.state.responses[fieldName]}
                                displayEmpty
                                onChange={(event) => this.onHandlePickList(event, fieldName)}
                                className={'fd-selectEmpty'}
                            >
                                <MenuItem value="">
                                    <em>{fieldName}</em>
                                </MenuItem>
                                {list.map((item) => (
                                    <div value={item.value}>
                                        <div style={{ color: 'black' }} value={item.value}>{item.item}</div>
                                        {item.hint ? <div style={{ padding: '0px 8px', color: '#ccc', fontSize: 12, fontStyle: 'italic' }}>{item.hint}</div> : null}
                                    </div>
                                ))}
                            </Select>
                            <FormHelperText>{fieldName}</FormHelperText>
                        </FormControl>
                    </div>
                )

            default:
                return (
                    <div style={{ height: 40, background: 'black', color: 'white', textAlign: 'center' }} className={questionClass}>
                        {prompt}
                    </div>
                );
        }
    }

    renderFromTemplate = (template, firstTime) => {
        if (!template) {
            return (null);
        }
        const { buttons } = template;

        let arr = [];

        let rowCount = template.rows.length;
        //if(this.state.locationNotes.length > 0){

        if (buttons) {
            if (buttons.cancel) {
                this.cancelButton = buttons.cancel;
            }
            if (buttons.skip) {
                this.skipButton = buttons.skip;
            }
            if (buttons.ok) {
                this.OKButton = buttons.ok;
            }
        }

        let counter = 0;

        const { classes } = this.props;

        return (
            // eslint-disable-line no-restricted-syntax
            template.rows.map((nextRow, index) => {
                return (
                    <Grid container spacing={0} style={{ border: `1px solid gray`, height: nextRow.rowHeight, }}>
                        {nextRow.columns.map((nextColumn) => {
                            return (
                                <Grid item xs={12} sm={nextRow.colWidth} style={{ border: `1px solid gray`, minHeight: 100, fontSize: 12 }}>
                                    {nextColumn.entries.map((entry, i) => {
                                        return (
                                            <div style={{ height: entry.height, boxSizing: `border-box` }}>

                                                {entry.title ?
                                                    <div className={'canvas-field-name'}>
                                                       {entry.title}
                                                        <div style={{ float: `right`, fontSize: 16 }}>
                                                            <Tooltip disableFocusListener title={entry.prompt} classes={{ tooltip: classes.lightTooltip }}>
                                                                <a className={`canvas-info`}>
                                                                    <i className="fa fa-info-circle" aria-hidden="true" ></i>
                                                                </a>
                                                            </Tooltip>
                                                            {this.state.locationFields.includes(entry.fieldName) ?
                                                                <a className={`canvas-info`}>
                                                                    <i className="far fa-comment-dots" style={{ color: '#ff5959' }} aria-hidden="true" onClick={() => this.openLocDialog(entry.fieldName)}></i>
                                                                </a> :
                                                                <a className={`canvas-info`}>
                                                                    <i className="far fa-comment-dots" aria-hidden="true" onClick={() => this.openLocDialog(entry.fieldName)}></i>
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ float: `right` }}>? N</div>
                                                }
                                                {this.renderFormItem(entry)}
                                            </div>
                                        )
                                    })}
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            })
        )
        //}
    }

    renderForm = () => {
        const { name } = this.state;
        if (name === '' || this.state.iAmOpen === false) {
            return (<div>Loading...</div>);
        } else {
            return (this.renderFromTemplate(this.state.template, false));
        }
    }

    renderGrid = () => {
        const { name } = this.state;
        if (this.state.loading) {
            return (<div>Loading...</div>);
        } else {
            return (this.renderFromTemplate(this.state.template, false));
        }
    }

    renderAccordian = () => {
        const { expanded } = this.state;
        const { classes } = this.props;
        const calendarStrings = {
            lastDay: 'LT [Yesterday] ',
            sameDay: 'LT [Today]',
            nextDay: 'LT [Tomorrow]',
            lastWeek: '[last] dddd [at] LT',
            nextWeek: 'dddd [at] LT',
            sameElse: 'L'
        };
        const { name } = this.state;
        if (this.state.loading) {
            return (<div>Loading...</div>);
        } else {
            return (
                <div>
                    <hr></hr>
                    <h3>Notes by Section</h3>
                    <hr></hr>
                    <ExpansionPanel expanded={expanded === 'panel'} onClick={() => this.getNotes('noLocation')} onChange={this.handleChange('panel')} style={{ marginTop: 10 }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>{this.props.canvasTitle}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.sectionWiseNotes.length > 0 ?
                                <Grid container spacing='24' style={{ paddingRight: '60px' }}>
                                    {this.state.sectionWiseNotes.map((note, i) => {
                                        return (
                                            <Grid item xs={4}>
                                                <div className={classes.cardList} style={{ fontFamily: "Montserrat", lineHeight: '25px', paddingLeft: '8px' }}>
                                                    <CardHeader
                                                        avatar={
                                                            <UserAvatar username={note.author.firstName} size={'sm'} />
                                                        }
                                                        title={<Profile name={note.author.firstName} username={note.author.username} isMention={false} />}
                                                        subheader={<Moment calendar={calendarStrings} date={note.creationDate} />}
                                                    />
                                                    <Divider />
                                                    <p><b>Subject: </b>{note.subject}</p>
                                                    <Divider />
                                                    <p><b>Message: </b>{this.renderTheString(note)}</p>
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                : <p><b>No Notes</b></p>}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {this.state.locationTypesArr.map((item, index) => {
                        return (
                            <ExpansionPanel expanded={expanded === 'panel' + index} onClick={() => this.getNotes(item)} onChange={this.handleChange('panel' + index)} style={{ marginTop: 10 }}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>{item}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {this.state.sectionWiseNotes.length > 0 ?
                                        <Grid container spacing='24' style={{ paddingRight: '60px' }}>
                                            {this.state.sectionWiseNotes.map((note, i) => {
                                                return (
                                                    <Grid item xs={4}>
                                                        <div className={classes.cardList} style={{ fontFamily: "Montserrat", lineHeight: '25px', paddingLeft: '8px' }}>
                                                            <CardHeader
                                                                avatar={
                                                                    <UserAvatar username={note.author.firstName} size={'sm'} />
                                                                }
                                                                title={<Profile name={note.author.firstName} username={note.author.username} isMention={false} />}
                                                                subheader={<Moment calendar={calendarStrings} date={note.creationDate} />}
                                                            />
                                                            <Divider />
                                                            <p><b>Subject: </b>{note.subject}</p>
                                                            <Divider />
                                                            <p><b>Message: </b>{this.renderTheString(note)}</p>
                                                        </div>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : <p><b>No Notes</b></p>}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                    }
                </div>
            )
        }
    }

    handleChange = panel => async (event, expanded, locationType) => {
        this.setState({
            expanded: expanded ? panel : false,
            openLocationDialog: false
        });
    };

    getNotes = async (locationType) => {
        try {
            const notesResult = await api.get('/note/get/locationCount/' + this.state.productId + '/' + this.state.taskId + '/' + locationType)
            this.setState({
                sectionWiseNotes: notesResult.data.data.data.notes
            })
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    }

    noteStatus = (value) => {
        switch (value) {
            case 1:
                return (null);
            case 2:
                return (
                    <Typography> <Tooltip title="Suggestion"><i className="fa fa-lightbulb fa-2x" style={{ color: '#4d4dff' }}></i></Tooltip></Typography>
                    // <svg xmlns="http://www.w3.org/2000/svg" color="danger" width="24" height="24" viewBox="0 0 24 24"><path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" /></svg>
                );
            case 3:
                return (
                    <Typography> <Tooltip title="Requirement"><i className="fa fa-exclamation-triangle fa-2x" style={{ color: '#ff4d4d' }}></i></Tooltip></Typography>
                );
            case 4:
                return (
                    <Typography> <Tooltip title="Approval"><i className="far fa-square fa-2x" style={{ color: '#ffff4d' }}></i></Tooltip></Typography>
                );
            case 5:
                return (
                    <Typography> <Tooltip title="Decision"><i className="fa fa-question-circle fa-2x" style={{ color: '#d2a679' }}></i></Tooltip></Typography>
                    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" /></svg>
                );
            case 6:
                return (
                    <Typography> <Tooltip title="Approved"><i className="fa fa-check-square fa-2x" color="primary" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
                );
            case 7:
                return (
                    <Typography> <Tooltip title="Decided"><i className="fa fa-check-square fa-2x" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
                );
            case 8:
                return (
                    <Typography> <Tooltip title="Resolved"><i className="fa fa-check-square fa-2x" style={{ color: '#4dff4d' }}></i></Tooltip></Typography>
                );
            case 9:
                return (
                    <Typography> <Tooltip title="Not Approved"><i className="fa fa-times-circle fa-2x" style={{ color: '#ff4d4d' }}></i></Tooltip></Typography>
                );
            default:
                return (null);
        }
    }

    renderTheString(note) {
        if (note.mentions.length > 0) { //checking for mentions
            //console.log(note.mentions);
            //To remove the user Id from note content
            let a = note.note.replace(/##[a-z0-9]+/ig, "");
            //console.log(a);
            // To get the mention(user) ID
            // let userId = note.note.match(/##[a-z0-9]+/ig)[0].replace();
            // To remove the special character from note content expect for @ character
            let b = a.replace(/[^a-zA-Z0-9@. ]/g, "");
            //console.log(b);
            let replacedText;
            // Match @-mentions
            replacedText = reactStringReplace(b, /@([\w-\.]+@[\w-\.]+\.\w+)/g, (match, i) => (
                <a style={{ color: '#f50057' }} component="button" key={match + i} ><Profile name={"@" + match} username={match} isMention={true} /></a>
            ));

            return replacedText;
        } else
            return note.note;
    }

    render() {
        const vertical = 'top'; // for snackbar
        const horizontal = 'left' // for snackbar
        const { classes, users } = this.props;

        const mentionDataFormat = users.map(({ _id, username }) => ({ id: _id, display: username })); // Formatting according to mentions plugin data format
        return (
            <div>
                {this.state.isDialog ?
                    <Dialog
                        open={this.state.iAmOpen}
                        TransitionComponent={this.TransitionUp}
                        keepMounted
                        onClose={() => this.closeMe(`Cancel`)}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle className={'fd-minWidth'}>
                            {this.props.displayTitle}
                        </DialogTitle>
                        {this.state.loading ? (
                            <LoadingIndicator />
                        ) : (
                                <span>

                                    {this.state.errorMessage != '' ?
                                        <div className='fd-errorMessage'>{this.state.errorMessage}</div>
                                        : null}
                                    <div style={{ width: 400, height: 350, overflow: 'auto' }}>
                                        {this.renderForm()}
                                    </div>


                                </span>
                            )
                        }
                        {this.state.isWizard ?
                            <MobileStepper
                                steps={this.totalQuestions}
                                position="static"
                                activeStep={this.state.currentQuestion}
                                // className={classes.mobileStepper}
                                nextButton={
                                    <Button onClick={() => this.nextQuestion(1)} size="small" disabled={this.state.currentQuestion + 1 === this.totalQuestions}>
                                        Next
                                    <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button onClick={() => this.nextQuestion(-1)} size="small" disabled={this.state.currentQuestion === 0}>
                                        <KeyboardArrowLeft />
                                        Back
                                    </Button>
                                }
                            /> : null
                        }
                        <DialogActions>
                            {this.cancelButton !== 'DNU' ? (
                                <Button onClick={() => this.closeMe(`Cancel`)} color="default">
                                    {this.cancelButton}
                                </Button>) : null
                            }
                            {this.skipButton !== 'DNU' ? (
                                <Button onClick={() => this.closeMe(`Skip`)} color="primary" variant="contained">
                                    {this.skipButton}
                                </Button>) : null
                            }
                            {this.OKButton !== 'DNU' ? (
                                <span>
                                    {this.state.isWizard === false || ((this.state.currentQuestion + 1) === this.totalQuestions) ?
                                        <Button onClick={() => this.closeMe(`OK`)} color="primary" variant="contained">
                                            {this.OKButton}
                                        </Button> :
                                        <Button onClick={() => this.closeMe(`SaveAndContinue`)} color="primary" variant="contained">
                                            {this.OKButton}
                                        </Button>
                                    }
                                </span>) : null
                            }
                        </DialogActions>
                    </Dialog>
                    :
                    <div style={{ background: 'white' }}>
                        {this.renderGrid()}
                        {this.renderAccordian()}
                    </div>
                }
                <Snackbar
                    style={{ marginTop: 50 }}
                    key="sentMessage"
                    anchorOrigin={{ vertical, horizontal }}
                    TransitionComponent={this.TransitionRight}
                    open={this.state.openConfirm}
                    autoHideDuration={1000}
                    onClose={this.handleCloseSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={<span id="message-id">Saved....</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="primary"
                            className="snackX"
                            onClick={this.handleCloseSnack}
                        >
                        <i class="far fa-times-circle white-icon"></i>
            </IconButton>
                    ]}
                />
                <LocationNote
                    onClose={this.handleClose}
                    open={this.state.openLocationDialog}
                    locationType={this.state.locationType}
                    productId={this.state.productId}
                    taskId={this.state.taskId}
                    users={this.props.users}
                    storeLocationsValue={this.storeLocations}
                />
            </div>

        )
    };
}

Canvas.propTypes = {
    canvas: PropTypes.object,
    isDialog: PropTypes.bool,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    data: PropTypes.object,
    onClose: PropTypes.func,
    displayTitle: PropTypes.string,
    errorMessage: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Canvas);
