import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import TextField from '@material-ui/core/TextField';
import merge from 'lodash.merge';

import { MentionsInput, Mention } from 'react-mentions'

import defaultStyle from './defaultStyle'
import defaultMentionStyle from './defaultMentionStyle'
// import due from './example.module.css' 


const styles = theme => ({
    typography: {
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    margin: {
        margin: theme.spacing.unit,
    },
});

class TextBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responses: {
                'note': this.props.messageData
            },
            showMessageNoteId: null,
            showReply: true,
        }
    }
    componentWillMount() {
        if (!this.props.isEditing) {
            this.setState({ showReply: false });
        }
    }
    showReply() {
        this.setState({ showReply: true });
    }
    cancelReply() {
        this.setState({ showReply: false });
        if (this.props.isEditing)
            this.props.cancel(this.props.noteId);
    }
    // Reply or Send the note data and Note id 
    send(noteId) {
        if (Object.keys(this.state.responses).length != 0) {
            if (this.state.responses.note.trim() != "") {
                this.props.send(noteId, this.state.responses, this.props.isEditing, this.props.parentNoteId);
                this.cancelReply();
                this.setState(state => ({ responses: this.state.responses.note = "" }));
            }
        }
    }
    // textarea (reply)
    onChangeText = (e) => {
        // const id = e.target.id;
        const id = 'note';
        const text = e.target.value;
        let value = {};
        value[id] = text;
        const newState = merge({}, this.state.responses, value);
        this.setState({ responses: newState });
    };
    // renderSuggestion = (id, display, search, highlightedDisplay) => {
    // return (<div className="suggestion-item" style={{ color: '#f50057' }}> @{display} </div>);
    // }
    mentionDisplayTransform = (id, display, type) => {
        return ("@" + display)
    }
    render() {
        const { classes, noteId, note, isEditing } = this.props;
        return (
            <Typography component={'span'} >
                {this.state.showReply ? (
                    <form>
                        {/* <MentionsInput value={this.state.responses['note']} onChange={this.onChangeText.bind(this)} markup="@__display__" displayTransform={this.mentionDisplayTransform}>
                            <Mention
                                trigger="@"
                                data={this.props.users}
                                renderSuggestion={this.renderUserSuggestion}
                            />
                        </MentionsInput> */}
                        <MentionsInput
                            value={this.state.responses['note']}
                            onChange={this.onChangeText.bind(this)}
                            className={classes.textField}
                            style={defaultStyle}
                            markup="@[__display__](##__id__)"
                            placeholder={""}
                            displayTransform={this.mentionDisplayTransform}
                        >
                            <Mention
                                type="user"
                                trigger="@"
                                data={this.props.users}
                                // renderSuggestion={this.renderSuggestion}
                                // onAdd={onAdd}
                                style={defaultMentionStyle}
                            />

                            {/* <Mention
                                type="email"
                                trigger={emailRegex}
                                data={search => [{ id: search, display: search }]}
                                // onAdd={onAdd}
                                style={{ backgroundColor: '#d1c4e9' }}
                            /> */}
                        </MentionsInput>
                        {/* <TextField
                                id="note"
                                label="message"
                                className={classes.textField}
                                helperText=""
                                margin="normal"
                                variant="outlined"
                                name="note"
                                value={this.state.responses['note']}
                                onChange={this.onChangeText.bind(this)}
                                fullWidth
                                multiline
                                rowsMax="4"
                            /> */}
                        {/* reply button (send and cancel button) */}
                        <Button variant="contained" size="small" onClick={() => this.send(noteId)} color="primary" className={classes.button}>
                            Send
                                </Button>
                        <Button variant="contained" size="small" onClick={() => this.cancelReply()} className={classes.button}>
                            Cancel
                                </Button>
                        {/* reply textbox reply button (send and cancel button) */}
                    </form>) :
                    <Typography component={'span'} variant={'body2'}>{!isEditing ? (
                        <Button variant="contained" onClick={() => this.showReply()} size="small" className={classes.button}>
                            Reply
                        </Button>
                    ) : null}</Typography>}
            </Typography>);
    }
}

TextBox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextBox);
