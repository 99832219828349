import React, { Component } from 'react';

import PropTypes from 'prop-types';
import api from '../../../shared/api';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';

import HomeNavigation from '../../../components/User/Navigation/PRNavigation';
import AppData from '../../../shared/AppData';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import FormDialog from '../../../components/FormDialog/FormDialog';

import '../../../css/ProductRunner.scss';



const goldMac = require('../../../img/marketing/gold-mac.jpg');

class Dashboard extends Component {
    constructor(props) {
        super(props);
         this.state = {
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle:"" // this is what the user sees (could be blank)

        };
    }

    componentWillMount() {
        this.loadContent();
    }

    componentDidMount() {

    }

    loadContent = async () => {
        try {
            const users = await api.get('/user/getall');
            this.setState(
                {
                    users: users.data.data.data
                },
                () => {
                    // do this when setState() is finished...

                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    };

    componentWillReceiveProps(nextProps) {
    }

    // *******************************************
    //
    //    for dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
      };
    
      closeSignIn = () => {
        this.setState({ formDialogOpen: false });
      };

      formClosed = (formName, response) => {
        this.setState( {formDialogOpen: false });
      }

    render() {
        return (
            <div>
                <div className="navbar-margin">
                    {this.state.loading ? (
                        <LoadingIndicator />
                    ) : (
                            <div style={{ padding: 100 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.openDialog(`SampleForm`, `Test TypeForm in a Dialog`)}
                                >
                                    Dialog Form Test
                                </Button>
                                <div style={{ marginTop: 24 }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Full Page Form Test
                                </Button>
                                </div>
                            </div>
                        )}
                </div>
                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    onClose={this.formClosed}
                />
            </div>
        );
    }
}

export default Dashboard;
