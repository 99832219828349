// ***********************************
// AppData.js
// ***********************************

export default class AppData {
    static data = {
     JWT: null,
     user: null,
     userId: null,
     userRoles: [],
     settings: []
    };

    static get( variable ) {
        if( this.data[variable]) { 
            return this.data[variable];
        }
        if( variable === 'token') {
            this.userSignedIn();
            // try again...
            return(this.data.JWT);
        }
        return '';
    }

    static set( variable, value ) {
        this.data[variable] = value;
    }

    static getSetting(key, set = 'general') {
        if (this.data.settings.length === 0) {
            return null;
        }
        let setIndex = -1;
        for (let s = 0; s < this.data.settings.length; s++) {
            if (this.data.settings[s].name === set) {
                setIndex = s;
                break;
            }
        }
        if (setIndex === -1) {
            return (null);
        }
        if (this.data.settings[setIndex]) {
            return (this.data.settings[setIndex].values[key])
        } else {
            // it's not in the data set
            return (null);
        }
    }
    
    static userSignedIn() {
        if (this.data.JWT) {
            return true;
        }
        this.data.JWT = sessionStorage.getItem("pr_random_num");
        if( this.data.JWT ) {
            // reload all the other user data...
            const user = JSON.parse( sessionStorage.getItem('pr_user') );
            this.data.userId = user._id;
            this.data.user = user;
            this.data.userRoles = user.roles;
            return true;
        }
        return false;
    }

    static signUserIn(user) {
        this.data.userId = user._id;
        this.data.JWT = user.token;
        this.data.user = user;
        this.data.userRoles = user.roles;
        sessionStorage.setItem("pr_random_num", this.data.JWT);
        sessionStorage.setItem("pr_user", JSON.stringify({fullName: user.fullName, firstName: user.firstName, lastName: user.lastName, roles: user.roles, id: user._id}) )
        window.location.replace('/');
        window.location.reload();
    }

    static signUserOut() {
        this.data.JWT = null;
        sessionStorage.removeItem("pr_random_num");
        sessionStorage.removeItem("pr_user");
        window.location.replace('/');
        window.location.reload();
    }

}
