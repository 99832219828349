import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';

// Icon
import IconButton from '@material-ui/core/IconButton';
import ThumpUpIcon from '@material-ui/icons/ThumbUp';
import ThumpDownIcon from '@material-ui/icons/ThumbDown';
import CheckIcon from '@material-ui/icons/Check';
import ThumpUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 1,
  },
  color: {
    backgroundColor: '#E8E8E8'
  }
});

class Vote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      userId: JSON.parse(sessionStorage.getItem("pr_user")).id
    };
  }
  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  onVote(noteId, type, parentNoteId) {
    this.props.vote(noteId, type, parentNoteId);
    this.handleClose();
  }
  // voteCount(){
  //   return ;
  // }
  render() {
    const { classes, noteId, note, parentNoteId } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Typography className={classes.typography}>
          <IconButton
            aria-owns={open ? noteId : undefined}
            aria-haspopup="true"
            variant="contained"
            onClick={this.handleClick}
          >
            <ThumpUpOutlinedIcon />
          </IconButton>
          {/* {note.votes[0].length} */}
          {note.votes.map((vote, i) => {
            return <span key={i}>
              {vote.actionType == 1 && vote.voter.length > 0 ? (
                <IconButton onClick={() => this.onVote(noteId, 1, parentNoteId)}>
                  <Badge badgeContent={vote.voter.length} className={vote.voter.find(x => x.user._id == this.state.userId) ? classes.color : ' '}>
                    <ThumpUpIcon fontSize="small" />
                  </Badge>
                </IconButton>
              ) : null}
              {vote.actionType == 2 && vote.voter.length > 0 ? (
                <IconButton onClick={() => this.onVote(noteId, 2, parentNoteId)}>
                  <Badge badgeContent={vote.voter.length} className={vote.voter.find(x => x.user._id == this.state.userId) ? classes.color : ' '}>
                    <ThumpDownIcon fontSize="small" />
                  </Badge>
                </IconButton>
              ) : null}
              {vote.actionType == 3 && vote.voter.length > 0 ? (
                <IconButton onClick={() => this.onVote(noteId, 3, parentNoteId)}>
                  <Badge badgeContent={vote.voter.length} className={vote.voter.find(x => x.user._id == this.state.userId) ? classes.color : ' '}>
                    <CheckIcon fontSize="small" />
                  </Badge>
                </IconButton>
              ) : null}
            </span>
          })}
        </Typography>
        <Popover
          id={noteId}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.typography}>
            <IconButton onClick={() => this.onVote(noteId, 3, parentNoteId)}>
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => this.onVote(noteId, 1, parentNoteId)}>
              <ThumpUpIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => this.onVote(noteId, 2, parentNoteId)}>
              <ThumpDownIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Popover>
      </div>
    );
  }
}

Vote.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Vote);
