import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import AppData from './shared/AppData';

import Home from './routes/marketing/Home/Home';
import Dashboard from './routes/user/Dashboard/Dashboard';

import PRNavigation from './components/User/Navigation/PRNavigation';
import MyProducts from './routes/user/MyProducts/MyProducts';
import ProductTask from './routes/user/Product/Task';
import Task from './routes/user/Tasks/Task';

import ExamplePage from './routes/user/ExamplePage/ExamplePage';

import './css/App.scss';

const About = () => <h2>About</h2>;
const Users = () => <h2>Users</h2>;

const theme = createMuiTheme({
    overrides: {
      // Name of the component ⚛️ / style sheet
      MuiTab: {
        label: {
          fontSize: '14px !important'
        }
      },
      MuiTabs: {
        flexContainer: {
          background: '#888'
        }
      },
      MuiFormLabel: {
          root: {
            fontWeight: 600
          }
      },
      MuiFormHelperText: {
        root: {
          fontWeight: 600
        }
    },

    },
    typography: { useNextVariants: true },
  });

class App extends Component {
    render() {
        if (AppData.userSignedIn()) {
            return (
    
                    <Router onUpdate={() => window.scrollTo(0, 0)} >
                        <div>
                        <MuiThemeProvider theme={theme}>
                            <Switch>
                                <Route path="/" exact render={(props) => <PRNavigation {...props} TargetPage={MyProducts} />} />
                                <Route path="/my-products" exact render={(props) => <PRNavigation {...props} TargetPage={MyProducts} />} />
                                <Route path="/product" render={(props) => <PRNavigation {...props} TargetPage={ProductTask} />} />
                                <Route path="/task" exact render={(props) => <PRNavigation {...props} TargetPage={ProductTask} />} />
                                <Route path="/testtask" exact render={(props) => <PRNavigation {...props} TargetPage={Task} />} />

                                <Route path="/examplepage" exact render={(props) => <PRNavigation {...props} TargetPage={ExamplePage} />} />

                                <Route path="/dashboard" exact component={Dashboard} />
                                <Route path="/about/" component={About} />
                                <Route path="/users/" component={Users} />
                                <Route path="*" component={About} />
                            </Switch>
                            </MuiThemeProvider>
                        </div>
                    </Router>

            );
        } else {
            return (
                <Router>
                    <div>
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/home" exact component={Home} />
                            <Route path="/about/" component={About} />
                            <Route path="/users/" component={Users} />
                            <Route path="*" component={Home} />
                        </Switch>
                    </div>
                </Router>
            );
        }
    }
}

export default App;
