import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle'
import DotMenuIcon from '@material-ui/icons/MoreVert';
import MoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import LessIcon from '@material-ui/icons/ExpandLess';

import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';

import '../../../css/ProductRunner.scss';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// checkbox
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const boardColors = ['board-color0', 'board-color1', 'board-color2', 'board-color3'];
const boardColorCount = boardColors.length;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
});
class NoteBoardTop extends Component {
    constructor(props) {
        super(props);
        let ci = 0;
        if (props.index) {
            ci = props.index;
        }

        this.state = {
            // menuOpen: false,
            menuOpen: null,
            // menuSelection: null,
            searchOpen: false,
            searchTerm: '',
            boardName: '',
            boardColor: boardColors[ci],
            // checkbox
            menuSelection: this.props.menu,
        };
    }


    // *******************************************
    //
    //    gadgets 
    //
    // *******************************************

    openMenu = (event) => {
        this.setState({ menuOpen: event.currentTarget });
    };

    closeMenu = () => {
        this.setState({ menuOpen: null });
    };

    doMenuItem = (index) => {
        this.closeMenu();
        if (typeof this.state.menuSelection.find(x => x.value == true) === 'undefined') { // if no checkbox is selected then default active will be made as selected
            let currentState = this.state.menuSelection;
            currentState.find(x => x.key == 2).value = true;
            this.setState({
                menuSelection: currentState,
            });
        }
        this.props.onMenu(this.state.menuSelection);

    }
    onClickAdd = (index) => {
        this.props.onAdd(index);
    }

    openSearch = (index) => {
        this.setState({ searchOpen: true });
    }

    closeSearch = (index) => {
        this.setState({ searchOpen: false });
    }

    doSearch = (index) => {
        this.props.onSearch(index, this.state.searchTerm);
    }
    handleChange = name => event => {
        let currentState = this.state.menuSelection;
        currentState.find(x => x.key == name).value = event.target.checked
        if (currentState.find(x => x.key == 1).value) { // if all is selected rest of the checkbox is unchecked and disabled = true
            currentState.forEach((element, index) => {
                if (element.key != 1) {
                    element.value = false;
                    element.disabled = true;
                }
            });
        } else {
            currentState.forEach((element, index) => { // disabled = false
                if (element.key != 1) {
                    element.disabled = false;
                }
            });
        }
        this.setState({
            menuSelection: currentState,
        });
    };


    render() {
        const props = this.props;
        const { menuOpen, menuSelection } = this.state;
        const { classes } = this.props;
        const { gilad, jason, antoine } = this.state;
        const error = [gilad, jason, antoine].filter(v => v).length !== 2;
        return (
            <span>
                <div className={`board-name`}>
                    {props.title}
                    <div className={`board-gadgets`}>
                        {/* {this.props.onAdd ? */}
                        {(this.props.onAdd && this.props.menu) ?
                            <IconButton onClick={() => this.onClickAdd(`${this.props.index}`)} className={'board-gadget'} color='inherit'>
                                <AddIcon />
                            </IconButton> : null
                        }
                        {/* {this.props.onSearch ? */}
                        {(this.props.onSearch && this.props.menu) ?
                            <IconButton onClick={() => this.openSearch(`${this.props.index}`)} className={'board-gadget'} color='inherit'>
                                <SearchIcon />
                            </IconButton> : null
                        }
                        {(this.props.onMenu && this.props.menu) ?
                            // <IconButton onClick={() => this.openMenu(`${this.props.index}`)} className={'board-gadget'} color='inherit'>
                            //     <DotMenuIcon />
                            // </IconButton> : null
                            <IconButton onClick={this.openMenu} className={'board-gadget'} color='inherit'>
                                <DotMenuIcon />
                            </IconButton> : null
                        }
                    </div>
                    <div className={'board-subtitle'}>{props.subTitle}</div>
                </div>
                <div className={`board-bar ${this.state.boardColor}`}></div>
                    {menuSelection.length > 0 ? (<Menu
                    anchorEl={menuOpen}
                    open={Boolean(menuOpen)}
                    onClose={this.closeMenu}
                >
                    <div className={classes.root}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {menuSelection.map(option => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox disabled={option.disabled} checked={option.value} onChange={this.handleChange(option.key)} value={option.value} />
                                        }
                                        label={option.label}
                                    />
                                ))}
                                <Button variant="contained" color="primary" onClick={() => this.doMenuItem()} className={classes.button}>
                                    Done
                                </Button>
                            </FormGroup>
                        </FormControl>
                    </div> </Menu>) : null}
                    
               
            </span>
        );
    }
}

NoteBoardTop.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    index: PropTypes.number,
    onAdd: PropTypes.func,
    onSearch: PropTypes.func,
    onMenu: PropTypes.func,
    menu: PropTypes.array
};
export default withStyles(styles)(NoteBoardTop);
