import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import merge from 'lodash.merge';

// *********** Material UI *************
import { Grid, Dialog, DialogTitle, DialogActions, DialogContent, FormControlLabel, Tooltip, withStyles } from '@material-ui/core';
import { Button, TextField, Checkbox, FormControl, Select, InputLabel, FormHelperText, Input, MenuItem, Snackbar, IconButton } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// **********  PR Components ****************
import api from '../../../shared/api';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import AppData from '../../../shared/AppData';
import { EINTR } from 'constants';

// ******** WYSIWYG EDITOR *********************
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// ************* Test documents *****************

const testDocument = {
    formName: 'TestDoc',
    isTypeForm: false,
    form: [
        { type: 'text', prompt: 'Field 1', placeholder: 'Enter...', required: true },
        { type: 'text', prompt: 'Field 2', placeholder: '(optional)', required: false },
        { type: 'text', prompt: 'Field 3', placeholder: 'Required', required: true },
        { type: 'text', prompt: 'Field 4', placeholder: '', required: true },
    ],
    buttons: {
        cancel: 'Cancel',
        ok: 'Save',
        skip: 'Skip'         // DNU = Do Not Use
    }
};

// ******************* CSS CLASSES ***********************
const styles = theme => ({
    lightTooltip: {
      backgroundColor: theme.palette.common.white,
      color: '#222',
      boxShadow: theme.shadows[1],
      fontSize: 16,
    },
})

// ******************* Document Component ******************

class Document extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minHeight: 800,
            productId: null,
            task: null,
            loading: false,
            name: '',
            template: null,
            firstTime: true,
            errorMessage: '',
            hasData: false,
            responses: {},
            docXPos: 0,
            editorState: EditorState.createEmpty(),

        };
        this.onChangeText = this.onChangeText.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.required = {};
        this.changed = {};
        this.original = {};

        this.forwardKeys = [13, 9, 39, 40, 34];
        this.backKeys = [37, 33, 38];
        this.formWidth = 420;
    }

    static cancelButton = 'Cancel';
    static skipButton = 'Skip';
    static OKButton = 'OK';
    static totalQuestions = 0;


    componentWillMount() {
        const { name } = this.props;
        let template = null;
        let hasData = false;
        let loading = false;

        if ( name !== '') {
            // need to load the form now.

            switch (name) {

                // looking for pre-defined forms...
                case 'TestDoc':
                    template = testDocument;
                    break;

                default:
                    template = this.loadFromDB(name);
                    loading = true;

                // actually load from the DB here.
            }
        } 


        this.setState({
            name,
            template,
            hasData,
            loading,
            docXPos: 0,

        });
    }

    componentDidMount() {
    }

    loadFromDB = async (formName) => {
        let template = null;        // set to null to 

        let firstTime = false;
        const userId = AppData.get('userId');

        try {
            const template = await api.get('/canvas/getTemplate');
            this.setState(
                {
                    formTemplate: template.data.data.data,
                    loading: false
                },
                () => {
                    // do this when setState() is finished...
                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
            template = testDocument;
            this.setState({ formTemplate: testDocument, loading: false });
        }

        return (template);



    }


    componentWillReceiveProps(nextProps) {
      
    }

    // *******************************************
    //
    //    document dialog 
    //
    // *******************************************
    TransitionUp(props) {
        return <Slide direction="up" {...props} />;
    }

    TransitionRight(props) {
        return <Slide {...props} direction="right" />;
      }

    openMe = () => {
        this.setState({ iAmOpen: true });
    };

    onChangeText = (e) => {
        const id = e.target.id;
        const text = e.target.value;
        let value = {};
        value[id] = text;
        const newState = merge({}, this.state.responses, value);
        this.setState({ responses: newState });
        this.changed[id] = true;
    };

    onBlur = (e) => {
        const id = e.target.id;
        if (this.changed[id] === true) {
            this.setState({ openConfirm: true });
            this.changed[id] = false;
        }
    }

    onHandleCheckbox = (e) => {
        const fieldName = e.target.id;
        const newState = merge({}, this.state.responses, { [fieldName]: e.target.checked });
        this.setState({ responses: newState });
    };

    onHandlePickList = (event, fieldName) => {
        const newState = merge({}, this.state.responses, { [fieldName]: event.target.value });
        this.setState({ responses: newState });
    }

    closeMe = (button) => {
        // button is one of: 'Cancel', 'Skip', 'OK'

        let valid = true;
        if (button === 'OK' && this.state.template.validate === true) {
            // then need to validate the form.
            for (const key of Object.keys(this.required)) {

                const value = this.state.responses[key] ? this.state.responses[key] : null;
                if (!value || value === '' || value === null) {
                    valid = false;
                }
            }

            if (!valid) {
                this.setState({ errorMessage: `Sorry, please complete this document' required fields` });
                return;
            }
        }

        let response = { action: 'close', status: button, data: this.state.responses };
        if (this.props.onClose) {
            this.setState({ loading: true });
            this.props.onClose(this.props.name, response);
        }
    };

  
    onKeyDown = (e, type) => {
        const { keyCode } = e;
        if (this.forwardKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf' || keyCode !== 13) {

            }
        }
        if (this.backKeys.indexOf(keyCode) >= 0) {
            if (type === 'tf') {

            }
        }
        return null;
    }

    handleCloseSnack = () => {
        this.setState({ openConfirm: false });
      };

      // ********************************************
      //
      // deal with Editor
      // 
      // ********************************************
      onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
      };

   

    renderFromTemplate = (template, firstTime) => {
        if (!template) {
            return (null);
        }
        const { buttons } = template;

        const rowCount = template.rows.length;


        if (buttons) {
            if (buttons.cancel) {
                this.cancelButton = buttons.cancel;
            }
            if (buttons.skip) {
                this.skipButton = buttons.skip;
            }
            if (buttons.ok) {
                this.OKButton = buttons.ok;
            }
        }

    }

    renderForm = () => {
        const { name } = this.state;
        if (name === '' || this.state.iAmOpen === false) {
            return (<div>Loading...</div>);
        } else {
            this.renderFromTemplate(this.state.template, false)
            return (<div></div>);
        }
    }


    render() {
        const vertical = 'top'; // for snackbar
        const horizontal = 'left' // for snackbar
        
        return (
            <div>
                {this.state.isDialog ?
                    <Dialog
                        open={this.state.iAmOpen}
                        TransitionComponent={this.TransitionUp}
                        keepMounted
                        onClose={() => this.closeMe(`Cancel`)}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle className={'fd-minWidth'}>
                            {this.props.displayTitle}
                        </DialogTitle>
                        {this.state.loading ? (
                            <LoadingIndicator />
                        ) : (
                                <span>

                                    {this.state.errorMessage != '' ?
                                        <div className='fd-errorMessage'>{this.state.errorMessage}</div>
                                        : null}
                                    <div style={{ width: 400, height: 350, overflow: 'auto' }}>
                                        {this.renderForm()}
                                    </div>


                                </span>
                            )
                        }
                        {this.state.isWizard ?
                            <MobileStepper
                                steps={this.totalQuestions}
                                position="static"
                                activeStep={this.state.currentQuestion}
                                // className={classes.mobileStepper}
                                nextButton={
                                    <Button onClick={() => this.nextQuestion(1)} size="small" disabled={this.state.currentQuestion + 1 === this.totalQuestions}>
                                        Next
            <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button onClick={() => this.nextQuestion(-1)} size="small" disabled={this.state.currentQuestion === 0}>
                                        <KeyboardArrowLeft />
                                        Back
          </Button>
                                }
                            /> : null
                        }
                        <DialogActions>
                            {this.cancelButton !== 'DNU' ? (
                                <Button onClick={() => this.closeMe(`Cancel`)} color="default">
                                    {this.cancelButton}
                                </Button>) : null
                            }
                            {this.skipButton !== 'DNU' ? (
                                <Button onClick={() => this.closeMe(`Skip`)} color="primary" variant="contained">
                                    {this.skipButton}
                                </Button>) : null
                            }
                            {this.OKButton !== 'DNU' ? (
                                <span>
                                    {this.state.isWizard === false || ((this.state.currentQuestion + 1) === this.totalQuestions) ?
                                        <Button onClick={() => this.closeMe(`OK`)} color="primary" variant="contained">
                                            {this.OKButton}
                                        </Button> :
                                        <Button onClick={() => this.closeMe(`SaveAndContinue`)} color="primary" variant="contained">
                                            {this.OKButton}
                                        </Button>
                                    }
                                </span>) : null
                            }
                        </DialogActions>
                    </Dialog>
                    :
                    <div style={{background:'white', padding: `8px 24px`, fontSize:18, minHeight:this.state.minHeight}}>
                        <Editor
                            editorState={this.state.editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    </div>
                }
                <Snackbar
                    style={{ marginTop: 50 }}
                    key="sentMessage"
                    anchorOrigin={{ vertical, horizontal }}
                    TransitionComponent={this.TransitionRight}
                    open={this.state.openConfirm}
                    autoHideDuration={1000}
                    onClose={this.handleCloseSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={<span id="message-id">Saved....</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="primary"
                            className="snackX"
                            onClick={this.handleCloseSnack}
                        >
                        <i class="far fa-times-circle white-icon"></i>
            </IconButton>
                    ]}
                />
            </div>

        )
    };
}

Document.propTypes = {
    isDialog: PropTypes.bool,
    isOpen: PropTypes.bool,
    name: PropTypes.string.isRequired,
    data: PropTypes.object,
    onClose: PropTypes.func,
    displayTitle: PropTypes.string,
    errorMessage: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Document);
