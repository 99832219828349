import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';

import '../../../css/Marketing.scss';


class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formDialogOpen: false,
            formName: 'SignIn',   // this describes the form to the SYSTEM
            formDisplayTitle: 'Please Sign In', // this is what the user sees (could be blank)
            errorMessage: ''
        };
    }


    // *******************************************
    //
    //    sign in dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    closeSignIn = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = async (formName, response) => {
        if (formName === 'SignIn' && response.status === 'OK') {
            try {
                const user = await api.post('/user/sili', {
                    username: response.formData['Email'], password: response.formData['Password']
                });
                if (user.data.data.success) {
                    this.setState(
                        {
                            user: user.data.data.data,
                            formDialogOpen: false
                        },
                        () => {
                            // do this when setState() is finished...

                            AppData.signUserIn(this.state.user);
                        }
                    );
                } else {
                    this.setState({errorMessage: 'Sorry, no username/password match. Please try again'});
                }
            } catch (e) {
                // do this if api.get() throws an error;
                alert(e);
            }

        } else {
            this.setState({formDialogOpen: false });
        }
    }


    render() {
        return (
            <span>
                <a onClick={() => this.openDialog(this.state.formName, this.state.formDisplayTitle)}>Sign In</a>

                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    errorMessage={this.state.errorMessage}
                    onClose={this.formClosed}
                />
            </span>
        );
    }
}

export default SignIn;
