import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import api from '../../../shared/api';


import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import FormDialog from '../../../components/FormDialog/FormDialog';
import BoardTop from '../../../components/User/Board/BoardTop';
import ProductCard from '../../../components/User/Board/ProductCard';
import Alert from '../../../components/Dialogs/Alert';

import '../../../css/ProductRunner.scss';
import SessionData from '../../../shared/SessionData';

class MyProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            products: [],
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle: "", // this is what the user sees (could be blank)
            alertOpen: false,
            navNode: props.navRef,
            // below are the default stages in case the DB isn't populated
            stages: [ 
                { name: 'Ideas', tagLine: 'Record your every wish' },
                { name: 'Concept', tagLine: 'Validation Playground' },  
                { name: 'Invent', tagLine: 'Define, design, develop' },
                { name: 'Accelerate', tagLine: 'Launch, listen, learn' },
              ] ,
        };
    }

    getSettings = async () => {
        const stages = await SessionData.getSetting('stages');
        console.log("stages", stages);
        this.setState({stages});
    }

    componentWillMount() {
        this.loadContent();
        this.state.navNode.setState({ productName: 'My Products', bcolor: 'black', color: 'white'});
        this.getSettings();

    }

    loadContent = async () => {
        try {
            const p = await api.get('/product/getall');
            this.setState(
                {
                    products: p.data.data.data
                },
                () => {
                    // do this when setState() is finished...

                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }
    };

    // *******************************************
    //
    //    for form dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    closeSignIn = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = async (formName, response) => {
        if (formName === 'NewProduct' && response.status !== 'Cancel') {

            try {
                response.formData.prPhase = this.state.addPrPhase;
                const newProduct = await api.post('/product/create', {formData: response.formData});
                if (newProduct.data.data.success) {
                    this.setState({ formDialogOpen: false });
                    this.loadContent();
                } else {
                    this.setState({ errorMessage: `Sorry, couldn't create this product. Please try again` });
                }
            } catch (e) {
                // do this if api.get() throws an error;
                alert(e);
            }
        } else {
            this.setState({ formDialogOpen: false });
        }
    }

    doAlertAction = (response, responseId, action, objectId ) => {
        this.setState({alertOpen:false});
        if (action === 'Delete' && responseId === 1) {
            alert( 'delete', objectId);
        }
    }

    // *******************************************
    //
    //    for "gadgets" on top of columns
    //
    // *******************************************
    addNewProduct = (prPhase) => {
       // test alert this.setState({alertOpen: true});
       this.setState({ addPrPhase: prPhase });
       this.openDialog('NewProduct', `Add ${prPhase === 0 ? 'an Idea' : 'a Product'}`);
    }

    searchColumn = (columnNumber, searchTerm) => {
        alert(searchTerm);
    }

    executeMenuSelection = (columnNumber, menuSelection) => {
        alert(menuSelection);
    }

    // *******************************************
    //
    // input on product card menu 
    //
    // ******************************************

    productMenuAction = (action, productId) =>  {
       // alert( `${response}  id: ${productId}`);
        if (action === 'Delete' ) {
            this.setState( {
                alertOpen: true,
                alertTitle: `Delete Product`,
                alertMessage: `You're sure you want to delete this product? There's no going back bwahahaha.`,
                alertButton1: `Yes, Delete it`,
                alertButton2: null,
                alertCancel: true,
                alertAction: action,
                alertObjectId: productId
            })
        }
    }



    render() {
        // eslint-disable-next-line no-labels
        const { stages } = this.state;

        return (
            <div>
                {this.state.loading ? (
                    <LoadingIndicator
                        message={`Loading your products...`}
                    />
                ) : (
                        <div className='darkPage flex-grow'>
 
                                <Grid container spacing={32}>
                                    {stages.map((stage, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <BoardTop
                                                title={stage.name}
                                                subTitle={stage.tagLine}
                                                index={index}
                                                onAdd={() => this.addNewProduct(index)}
                                                // menu={['Open', 'Edit', 'Delete']}
                                                menu={[]}
                                            />
                                            {this.state.products.map((nextProduct) => (
                                                <span>
                                                    {(nextProduct.prPhase === index) && (
                                                        <ProductCard
                                                            product={nextProduct}
                                                            index={index}
                                                            menu={['Open', 'Delete']}
                                                            menuAction={this.productMenuAction}
                                                        />
                                                    )}
                                                </span>
                                            ))
                                            }
                                            <div className={`center`}>
                                                <Button
                                                    variant="outlined" color="primary"
                                                    onClick={() => this.addNewProduct(index)}
                                                >
                                                    Add {index === 0 ? 'Idea' : 'Product'}
                                        </Button>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                        </div>
                    )}
                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    onClose={this.formClosed}
                />
                <Alert 
                    isOpen={this.state.alertOpen}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    button1={this.state.alertButton1}
                    button2={this.state.alertButton2}
                    cancel={this.state.alertCancel}
                    doAction={this.doAlertAction}
                    action={this.state.alertAction}
                    objectId={this.state.alertObjectId}
                />
            </div>
        );
    }
}

export default MyProducts;
