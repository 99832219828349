import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import PropTypes from 'prop-types';
import api from '../../../shared/api';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Navigation from '../../../components/Marketing/Navigation/Navigation';
import AppData from '../../../shared/AppData';
import SessionData from '../../../shared/SessionData';

import '../../../css/Marketing.scss';

import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import FormDialog from '../../../components/FormDialog/FormDialog';

const goldMac = require('../../../img/marketing/gold-mac.jpg');

class Home extends Component {
    constructor(props) {
        super(props);
        const aid = props.location.search.split('=')[1];
        this.state = {
            formDialogOpen: false,

        };
    }

    componentWillMount() {
        this.loadContent();
        const settings = SessionData.getSetting('Stages');
    }

    componentDidMount() {

    }

    loadContent = async () => {
        try {
            const users = await api.get('/user/getall');
            this.setState(
                {
                    users: users.data.data.data
                },
                () => {
                    // do this when setState() is finished...
                  
                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert( e );
        }
    };

    componentWillReceiveProps(nextProps) {
    }

    render() {
        return (
            <div className="marketing--body">
                <Navigation />
                <div className="marketing--navbar-margin">
                    {this.state.loading ? (
                        <LoadingIndicator />
                    ) : (
                            <div>
                                <div className=" marketing--homepanel marketing--panel1">
                                    <div className="">
                                        <div className="marketing--panel-copy-left">
                                            <h1 className="marketing--panel-h1">At last, one tool
                <br /> to rule them all.</h1>
                                            <p className="marketing--panel-p">
                                                Product Managers finally have a single purpose-built tool to support the entirety of their work: Product Runner
                <br />
                                                <br /> Until now, PM’s - and the teams they work with - used dozens of tools in a scattershot process that wasted valuable time recreating wheels and trying to cover all the bases. Managing products wasn't repeatable, portable,
                                                or measurable.
                <br />
                                                <br /> Now, with Product Runner, you have one tool in which to create the entire truth of the product you’re managing.
            </p>
                                        </div>
                                    </div>
                                    <div className="marketing--panel-img-bg-right">
                                        <img src={goldMac} alt="Product Runner Screen Sample" />
                                    </div>
                                </div>
                                <div >PANEL 2 </div>
                            </div>
                        )}
                </div>
                        <FormDialog 
                            isOpen={this.state.formDialogOpen}
                            displayTitle={this.state.formDisplayTitle}
                            formName={this.state.formName}
                            formData={null}
                            onClose={this.formClosed}
                        />
            </div>
        );
    }
}

export default Home;
