import axios from 'axios';
import { API_ROOT } from './constants';
//import * as constants from 'constants';
import AppData from './AppData';

const token = AppData.get('token') || '';

const api = axios.create({
  baseURL: API_ROOT,
  timeout: 40000
});

if (token) {
  api.defaults.headers.common['X-Token'] = token;
}

export default api;