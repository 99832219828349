import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// ******************* material ui ******************
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InputIcon from '@material-ui/icons/InputSharp';
import HomeIcon from '@material-ui/icons/Home';
import PlusIcon from '@material-ui/icons/Add'
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import CheckIcon from '@material-ui/icons/CheckOutlined'

// **********  PR Components ****************
import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';
import UserAvatar from '../../../components/User/UserAvatar/UserAvatar';

import Dashboard from '../../../routes/user/Dashboard/Dashboard';

import '../../../css/ProductRunner.scss';

// ************** local variables/constants ******************
const blackLogo = require('../../../img/logos/logo-black-transparent.png');
const redLogo = require('../../../img/logos/logo-red-transparent.png');
const drawerWidth = 200;

const styles = theme => ({
    root: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: 6,
        marginRight: 2,
        float: 'right'
    },
    prAppBar: {
        backgroundColor: 'black',
        borderBottom: '1px #292c39 solid'
    },
    appBar: {
        backgroundColor: 'white',
        //   borderBottom: '1px #292c39 solid',
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: 36,
        minHeight: 'unset',
        alignItems: 'unset'
    },
    toolbar: {
        minHeight: 'unset',
        alignItems: 'inherit',
        paddingTop: 8
    },
    appContentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth + 48}px)`,
    },
    appContentUnShift: {
        marginLeft: 56,
        width: `calc(100% - 105px)`,
    },
    title: {
        display: 'none',
        color: 'white',
        verticalAlign: 'top',
        fontFamily: "'Montserrat', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important",
        fontSize: 15,
        marginRight: 20,
        marginLeft: -6,
        [theme.breakpoints.up('sm')]: {
            display: 'inline-block',
        },
    },
    logo: {
        height: 26,
        marginRight: 4,
        marginLeft: -6
    },
    prColor: {
        color: '#f85756'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 200,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        marginTop: -11
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: '#292c39'
    },
    drawerIcon: {
        color: 'white'
    },
    drawerMenuItem: {
        color: '#7f8fa4'
    },
    drawerOpen: {
        // marginTop: 64,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        //  marginTop: 64,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing.unit * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            // width: theme.spacing.unit * 9 + 1,
            width: 56
        },
    },
    productNameOpen: {
        marginLeft: 200,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    productNameClosed: {
        marginLeft: 42,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbarSpacing: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
       // minHeight: 28,
    },
    content: {
        position: 'absolute',
        left: 0,
        flexGrow: 1,
        padding: '0px 24px',
       // padding: theme.spacing.unit * 3,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
});



class PRNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productName: "My Products",
            anchorEl: null,
            mobileMoreAnchorEl: null,
            drawerOpen: true,
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle: "", // this is what the user sees (could be blank)
            noteCount:0, //
            bcolor: 'white',
            color: 'black',
        };
        this.navRef = React.createRef();
    }

    componentWillMount() {
        this.loadContent();
        window.scrollTo(0, 0);
    }

    collapseNavbar = () => {
        var topmin = 450;
        if (window.location.prTopMin) {
            topmin = window.location.prTopMin;
        }
        const navbar = document.getElementById("navbar");
        if (window.screenTop > topmin) {
            /*
            $(".navbar-fixed-top").addClass("top-nav-collapse");
            $(".navbar2-collapse").addClass("top-navbar2-collapse");
            $(".btn-nav-collapse").addClass("top-btn-nav-collapse");
            */
        } else {
            /*
            $(".navbar-fixed-top").removeClass("top-nav-collapse");
            $(".navbar2-collapse").removeClass("top-navbar2-collapse");
            $(".btn-nav-collapse").removeClass("top-btn-nav-collapse");
            */
        }
    }
    componentDidMount() {

        // ***************************************************
        //
        // navigation display/collapse
        //
        // ***************************************************

        // collapse the navbar on scroll
        window.location.prTopMin = 75;


        //   window.addEventListener('scroll', this.collapseNavbar );

        //  $(window).scroll(collapseNavbar);
        //   $(document).ready(collapseNavbar);

        // 
        /*
        $(function () {
            $('a.page-scroll').bind('click', function (event) {
                var $anchor = $(this);
                $('html, body').stop().animate({
                    scrollTop: $($anchor.attr('href')).offset().top
                }, 1500, 'easeInOutExpo');
                event.preventDefault();
            });
        });

        // Closes the Responsive Menu on Menu Item Click
        $('.navbar-collapse ul li a').click(function () {
            $(".navbar-collapse").collapse('hide');
        });
       */
    }

    loadContent = async () => {
        try {
            const noteCount = await api.get('/note/getnotecountbyuser/'+JSON.parse(sessionStorage.getItem("pr_user")).id);
            this.setState(
                {
                    noteCount: JSON.stringify(noteCount.data.data.data)
                },
                () => {
                    // do this when setState() is finished...

                }
            );
        } catch (e) {
            // do this if api.get() throws an error;
            alert(e);
        }

    };

    componentWillReceiveProps(nextProps) {
    }

    signOut = () => {

        AppData.signUserOut();

    }

    testGetProducts = async (e) => {
        alert('test');
        const f = e;
        const products = await api.get('/product/getall');
        const data = products.data.data;
    }
    // *******************************************
    //
    //    navigation dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
    };

    closeSignIn = () => {
        this.setState({ formDialogOpen: false });
    };

    formClosed = (formName, response) => {
        this.setState({ formDialogOpen: false });
    }

    // *****************************************
    //
    // navigation controll stuff
    //
    // *****************************************
    toggleLeftNav = function () {

        // $("#leftsidenav").toggleClass("sidebar-hidden");
    }

    toggleAside = function () {

        //  $("#rightsidenav").toggleClass("aside-menu-hidden");
    }

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ drawerOpen: false });
    };

    render() {
        const { anchorEl, mobileMoreAnchorEl, noteCount } = this.state;
        const { classes, theme, TargetPage } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
                <MenuItem onClick={this.signOut}>Sign Out</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>Messages</p>
                </MenuItem>
              
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        )

        return (
            <div className={classes.root}>
            {
                <AppBar
                    position="fixed"            
                   // className={classNames(classes.appBar, {
                   //     [classes.appContentShift]: this.state.drawerOpen,
                   // })}
                    className={classes.appBar}
                    style={{ background: `${this.state.bcolor}`, color: `${this.state.color}`}}
                >
                    <Toolbar 
                      className={classes.toolbar}
                     //disableGutters={!this.state.drawerOpen}
                     >
                     <Link to={`/product?id=${this.state.productId}`} 
                     className = { this.state.drawerOpen ? classes.productNameOpen : classes.productNameClosed }
                     style={{color:`${this.state.color}`}}
                     >
                                    {this.state.productName}
                      </Link>
                       {/*}
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Find a product…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                        </div>
                            */}
                         
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <IconButton color="inherit">
                            {noteCount > 0 ? (<Badge badgeContent={noteCount} color="secondary">
                                    <MailIcon />
                                </Badge>) :  <MailIcon /> }
                            </IconButton>
                            <IconButton
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                                style={{paddingTop:4}}
                            >
                              <UserAvatar username={`eddie`} size={'sm'}/>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                }
                {/*}
                 <div className={classes.sectionDesktop} style={{position: 'absolute', top: 6, right: 10}}>
                            <IconButton color="inherit">
                                <Badge badgeContent={noteCount} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton>
                            <IconButton
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                            >
                              <UserAvatar username={`eddie`} size={'sm'}/>
                            </IconButton>
                        </div>
            */}
                <Drawer
                    variant="permanent"

                    classes={{
                        paper: classNames( classes.drawer, {
                            [classes.drawerOpen]: this.state.drawerOpen,
                            [classes.drawerClose]: !this.state.drawerOpen,
                        }),
                    }}
                    open={this.state.drawerOpen}
                >
                    <div >
                        <Link to={`/`}>
                            <ListItem button key='MyProducts'>

                                <ListItemIcon >
                                    <img className={classes.logo} src={redLogo}></img>
                            </ListItemIcon>
                                <div className={classes.title} variant="h6" color="inherit" noWrap>
                                    Product Runner
                            </div>
                            </ListItem>
                        </Link>
                    <Divider />
                        <IconButton 
                            onClick={this.handleDrawerOpen} 
                            className={classNames(classes.menuButton, classes.prColor, {
                                [classes.hide]: this.state.drawerOpen,
                            } ) }
                        >
                        <InputIcon className={`icon-0deg`} />
                        {/* <ChevronRightIcon /> */}
                        </IconButton>
                        <IconButton 
                            onClick={this.handleDrawerClose} 
                            className={classNames(classes.menuButton, classes.prColor, {
                                [classes.hide]: !this.state.drawerOpen,
                            } ) }
                        >
                         {/* <ChevronLeftIcon /> */}
                         <InputIcon className={`icon-180deg`} />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                    <Link to={`/my-products`}>
                                <ListItem button key='MyProducts'>
                                    <ListItemIcon className={classes.drawerIcon}><HomeIcon /> 
                               </ListItemIcon>
                                    <div className={classes.drawerMenuItem} primary='My Products'>My Products</div>
                                </ListItem>
                    </Link>
                    <Link to={`/new-product`}>
                                <ListItem button key='NewProduct'>
                                    <ListItemIcon className={classes.drawerIcon}><PlusIcon /> 
                                </ListItemIcon>
                                    <div className={classes.drawerMenuItem} primary='NewProduct'>New Product</div>
                                </ListItem>
                    </Link>
                    <Link to={`/notes`}>
                            <ListItem button key='Notes'>
                                <ListItemIcon className={classes.drawerIcon}>
                                    <Badge badgeContent={noteCount} style={{ backgroundColor: '#ff5959' }}>
                                        <MailIcon />
                                    </Badge>
                                </ListItemIcon>
                                <div className={classes.drawerMenuItem} primary='Notes'>Notes</div>
                            </ListItem>
                    </Link>

                    </List>
                    <Divider />
                    <List>
                    {['Task Example', 'Reports'].map((text, index) => (
                            <Link to={`/testtask`}>
                                <ListItem button key={text}>
                                    <ListItemIcon className={classes.drawerIcon}><InboxIcon /> 
                                </ListItemIcon>
                                    <div className={classes.drawerMenuItem} primary={text}>{text}</div>
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {['People', 'My Account', 'Settings'].map((text, index) => (
                            <ListItem button key={text} >
                                <ListItemIcon className={classes.drawerIcon}>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                <div   onClick={this.testGetProducts}  className={classes.drawerMenuItem} primary={text}>{text}</div>
                            </ListItem>
                        ))}
                        <ListItem button key={'examplepage'}>
                           <ListItemIcon className={classes.drawerIcon}><CheckIcon /></ListItemIcon>
                            <Link to='/examplepage'>
                                <div className={classes.drawerMenuItem} primary={'Example Page'}>{'Example page'}</div>
                            </Link>
                        </ListItem>
                    </List>
                </Drawer>
                <main className={classNames(classes.content, classes.appContent, {
                       [classes.appContentShift]: this.state.drawerOpen,
                       [classes.appContentUnShift]: !this.state.drawerOpen,
                   })}
                >
                    <div className={classes.toolbarSpacing} />
                    <TargetPage {...this.props} navRef={this} />
                </main>
                {renderMenu}
                {renderMobileMenu}

                <FormDialog
                    isOpen={this.state.formDialogOpen}
                    displayTitle={this.state.formDisplayTitle}
                    formName={this.state.formName}
                    formData={null}
                    onClose={this.formClosed}
                />
            </div >
        );
    }
}

PRNavigation.propTypes = {
    TargetPage: PropTypes.Component,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PRNavigation);
