import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import api from '../../../shared/api';
import AppData from '../../../shared/AppData';
import FormDialog from '../../FormDialog/FormDialog';
import OneList from './OneList';
import BoardTop from '../Board/BoardTop';

import '../../../css/ProductRunner.scss';
import genericPrImage from '../../../img/pr/images/pr-photo.png';

const boardColors = ['board-color0', 'board-color1', 'board-color2', 'board-color3'];

class TaskList extends Component {
    constructor(props) {
        super(props);

        let ci = 0;
        if (props.index) {
            ci = props.index;
        }

        const { asset, taskId } = props;
        this.state = {
            taskId,
            asset,
            view: 'list'        // list, kanban, type, masterlist
        };
    }

    imageDidNotLoad = () => {

    }

    // *******************************************
    //
    //    gadgets 
    //
    // *******************************************

    openMenu = () => {
        this.setState({ menuOpen: true });
    };

    closeMenu = () => {
        this.setState({ menuOpen: false });
    };

    doMenuItem = (index) => {
        this.props.onMenu(index, this.state.menuSelection);
    }

    // *******************************************
    //
    //    form input... form updated 
    //
    // *******************************************
    formUpdated = async (formName, response) => {
        if ( response.status !== 'Cancel') {

            try {
                /*
                response.formData.prPhase = this.state.addPrPhase;
                const newProduct = await api.post('/product/create', {formData: response.formData});
                if (newProduct.data.data.success) {
                    this.setState({ formDialogOpen: false });
                    this.loadContent();
                } else {
                    this.setState({ errorMessage: `Sorry, couldn't create this product. Please try again` });
                }
                */
               const updated = await api.post('/task/update', {taskId: response.task, responses: response.formData});
               alert(updated.data.status );
            } catch (e) {
                // do this if api.get() throws an error;
                alert(e);
            }
        } else {
            this.setState({ formDialogOpen: false });
        }
    }


    renderNextAsset = (nextAsset, index) => {
        console.log('next asset', nextAsset);
        if ( nextAsset.list && nextAsset.list.length > 0) {
            // a sub-list
            return(
            <OneList taskId={this.state.taskId} asset={nextAsset} width={`small`} index={index} />
            )
        } else {
            // not a sub-list, but a single task (assume it's a form)
            return (
                <Fragment>
                    <BoardTop
                           title={nextAsset.name}
                           index={index}
                    />
                    <div className={'tl-Box'}>
                        <FormDialog
                            isOpen={true}
                            isDialog={false}
                            isWizard={false}
                            form={nextAsset.task.assetTemplate}
                            formData={nextAsset.task.assetData.data}
                            errorMessage={this.state.errorMessage}
                            onClose={this.formUpdated}
                            height={450}
                            task={nextAsset.task._id}
                        />

                    </div>
                </Fragment>
            )
        }
    }

    renderAsList = () => {
        return (
            <Grid container spacing={32}>
                {this.state.asset.tasks.map((nextAsset, index) => (
                    <Grid item xs={12} sm={6}>
                        <div>
                        {this.renderNextAsset(nextAsset, index)}
                        </div>
                    </Grid>
                ))}   
            </Grid>
        );
    }

    render() {
        const props = this.props;
        const { title, image } = this.state;

        if (this.state.view === 'list') {
            return (this.renderAsList());
        }

        // get here, default view
        return (
            <span>
                {this.state.asset.taskLists.map((nextList) => (
                    <Fragment>
                        {nextList.name && (
                            <div>{nextList.name}</div>
                        )}
                    </Fragment>
                ))
                }
                
            </span>
        );
    }
}

TaskList.propTypes = {
    taskId: PropTypes.string,
    asset: PropTypes.object
};

export default TaskList;
