import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// ******************* global data *****************
import AppData from '../../../shared/AppData';

// ******************** forms ***********************
import FormDialog from '../../FormDialog/FormDialog';
import SignIn from '../SignIn/SignIn';
import Register from '../Register/Register';



// ********************** css **********************
import '../../../css/Marketing.scss';

// ********************** images ********************
const logo = require('../../../img/logos/pr-logo-opaque.png');
const reverseLogo = require('../../../img/logos/pr-logo-reverse-opaque.png');


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formDialogOpen: false,
            formName: "",   // this describes the form to the SYSTEM
            formDisplayTitle:"" // this is what the user sees (could be blank)
        };
    }

    componentWillMount() {
        this.loadContent();
        window.scrollTo(0, 0);
    }

    collapseNavbar = () => {
        var topmin = 450;
        if (window.location.prTopMin) {
            topmin = window.location.prTopMin;
        }
        const navbar = document.getElementById("navbar");
        if (window.screenTop > topmin) {
            /*
            $(".navbar-fixed-top").addClass("top-nav-collapse");
            $(".navbar2-collapse").addClass("top-navbar2-collapse");
            $(".btn-nav-collapse").addClass("top-btn-nav-collapse");
            */
        } else {
            /*
            $(".navbar-fixed-top").removeClass("top-nav-collapse");
            $(".navbar2-collapse").removeClass("top-navbar2-collapse");
            $(".btn-nav-collapse").removeClass("top-btn-nav-collapse");
            */
        }
    }
    componentDidMount() {

        // ***************************************************
        //
        // navigation display/collapse
        //
        // ***************************************************

        // collapse the navbar on scroll
        window.location.prTopMin = 75;


        //   window.addEventListener('scroll', this.collapseNavbar );

        //  $(window).scroll(collapseNavbar);
        //   $(document).ready(collapseNavbar);

        // 
        /*
        $(function () {
            $('a.page-scroll').bind('click', function (event) {
                var $anchor = $(this);
                $('html, body').stop().animate({
                    scrollTop: $($anchor.attr('href')).offset().top
                }, 1500, 'easeInOutExpo');
                event.preventDefault();
            });
        });

        // Closes the Responsive Menu on Menu Item Click
        $('.navbar-collapse ul li a').click(function () {
            $(".navbar-collapse").collapse('hide');
        });
       */
    }

    loadContent = async () => {

    };

    componentWillReceiveProps(nextProps) {
    }

    // *******************************************
    //
    //    navigation dialogs 
    //
    // *******************************************

    openDialog = (formName, formDisplayTitle) => {
        this.setState({ formDialogOpen: true, formName, formDisplayTitle });
      };
    
      closeSignIn = () => {
        this.setState({ formDialogOpen: false });
      };

      formClosed = (formName, response) => {

      }

    // *****************************************
    //
    // navigation controll stuff
    //
    // *****************************************
    toggleLeftNav = function () {

        // $("#leftsidenav").toggleClass("sidebar-hidden");
    }

    toggleAside = function () {

        //  $("#rightsidenav").toggleClass("aside-menu-hidden");
    }

    render() {
        return (
            <div>
                <nav id="marketingNavbar" className="marketing--navbar marketing--navbar-fixed-top" >

                    {/*  mobile */}
                    <div className="marketing--mobile">
                        <div className="marketing--mobile-brand">
                            <a href="">
                                <img className="mobile-logo" src={logo} alt="Product Runner" />
                            </a>
                            <div className="mobile-product-name">Product Runner</div>
                        </div>

                        {/*  mobile menus */}
                        <div className="dropdown pull-right">
                            <button id="mobile-menu" aria-expanded="false" type="button" className="marketing--navbar-toggle" data-toggle="dropdown">
                                <span className="fa fa-bars" style={{ fontSize: 20 }}></span>
                            </button>
                            <ul className="dropdown-menu mobile-menu-ul">
                                <li ng-repeat="aboutpage in aboutPages" className="mobile-menu-li">
                                    <a href="">Our Tools</a>

                                </li>
                                <li className="mobile-menu-li">
                                    <a href="/#!/application">Mission-Driven Design</a>
                                </li>
                                <hr className="mobile-nav" style={{ borderColor: "#003c6d" }} />
                                <div className="mobile-nav">
                                    <a href="" className="btn btn-nav btn-default">
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                        <i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp; Sign In
            </a>
                                </div>
                                <div className="mobile-nav">
                                    <a href="" className="btn btn-nav btn-default">
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                        <i className="fa fa-user-circle" aria-hidden="true"></i>&nbsp; Sign Up
            </a>
                                </div>

                                <li>
                                    <div className=" mobile-menu-li-btn">
                                        <a className="btn btn-lg btn-primary" href="">Sign In</a>
                                        <a className="btn btn-lg btn-primary" href="" style={{ float: 'right' }}>Sign Up</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/*  desktop nav */}
                    <div className="marketing--nav-desktop">
                        <a className="marketing--navbar-brand"  href="">
                            <div>
                                <img className="marketing--logo" src={logo} alt="Product Runner" />
                                <div className="marketing--productName">Product Runner</div>
                            </div>
                        </a>

                        <div className="floatright">
                            <div className="marketing--top-nav-link dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown">A Tool for Product Managers</a>
                                <ul className="dropdown-menu marketing--navbar-pulldown" role="menu">
                                    <li>
                                        <a href="" onClick="services.yesNo('Ready to Envision', 'Will you envision?', 'YN')">Envision</a>
                                    </li>
                                    <li>
                                        <a href="">Invent</a>
                                    </li>
                                    <li>
                                        <a href="">Scale</a>
                                    </li>
                                    <li>
                                        <a href="">Optimize</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="marketing--top-nav-link">
                                <a href="">Mission-Driven Design</a>
                            </div>


                            <div className="marketing--top-nav-button">
                                <div className="marketing--sign-in marketing--btn-nav-collapse" >
                                   <Register /> | 
                                   <SignIn />
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <FormDialog 
                            isOpen={this.state.formDialogOpen}
                            displayTitle={this.state.formDisplayTitle}
                            formName={this.state.formName}
                            formData={null}
                            onClose={this.formClosed}
                        />
            </div >
        );
    }
}

export default Navigation;
