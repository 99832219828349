// API
export const API_ROOT = 'http://localhost:8049/api';
export const API_URL = '/api';
export const IS_DEVELOPMENT = true;

// Timeouts
export const INITIAL_WARNING_DURATION = 1000 * 60 * 60; // 60 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 70; // on 70 minutes, force logout

// Application constants
export const DEFAULT_LOCALE = 'en';
export const RESIZE_START = 'RESIZE_START';
export const RESIZE_END = 'RESIZE_END';
export const SCROLLING_START = 'SCROLLING_START';
export const SCROLLING_END = 'SCROLLING_END';
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';
export const SWITCH_DIRECTION = 'SWITCH_DIRECTION';
export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SWITCH_MEASURE = 'SWITCH_MEASURE';
export const WINDOW_ACTIVE = 'WINDOW_ACTIVE';
export const WINDOW_INACTIVE = 'WINDOW_INACTIVE';

// Session constants
export const AUTHENTICATE_PENDING = 'AUTHENTICATE_PENDING';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_REJECTED = 'AUTHENTICATE_REJECTED';
export const AUTHENTICATE_RETRY = 'AUTHENTICATE_RETRY';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const CLEAR_SESSION = 'CLEAR_SESSION';

