// ***********************************
// SessionData.js
// ***********************************
import api from './api';
import AppData from './AppData';

const readDBSettings = async () => {
    const dbSettings = await api.get('/settings/getall');
    return( dbSettings);
}

export default class SessionData {


    static getSetting = async (key, set = 'general') => {

        const appSettings = AppData.getSetting(key, set);
        if (appSettings) {
            return  (appSettings);
        }

        // need to load it.
        // try from session storage
        const settingsString = sessionStorage.getItem("settings");
        if (settingsString) {
            const settings = JSON.parse(settingsString);
            AppData.set('settings', settings);
            const result = AppData.getSetting(key,set);
            return (result);
        } else {
            // read in from the db.

            const dbSettings = await readDBSettings();
            AppData.set('settings', dbSettings.data.data.settings);
            sessionStorage.setItem('settings', JSON.stringify(dbSettings.data.data.settings));
            const result = AppData.getSetting(key, set);
            return (result);
        }
    }
}
