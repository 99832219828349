import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import classnames from 'classnames';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UserAvatar from '../../../components/User/UserAvatar/UserAvatar';

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2,
  },
});

class Assign extends React.Component {
  state = {
    anchorEl: null,
    userId: JSON.parse(sessionStorage.getItem("pr_user")).id
  };

  handleClick = event => {
    this.props.loaduser(this.props.noteId)
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  assign = (assigneeId) => {
    this.props.assign(this.props.noteId, assigneeId);
    this.handleClose();
  }

  render() {
    const { classes, note } = this.props;
    let { users } = this.props;
    let assigneeId = null;
    if (note.assigned.length > 0) {
      assigneeId = note.assigned[0].assignedTo._id;
      users = users.filter(x => x._id != assigneeId);
    }
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    //console.log(assigneeId);
    return (
      <div>
        <Typography component={'span'}>
          Assignee
          {note.assigned.map(x => {
            return <Typography>{this.state.userId == note.author._id ? (
              <Button className={classnames(classes.expand, classes.button)}
                aria-owns={open ? 'assign' : undefined}
                aria-haspopup="true"
                // variant="contained"
                onClick={this.handleClick}
              >
                {x.assignedTo.firstName}
              </Button>
            ) : <Typography component={'span'}> {x.assignedTo.firstName} </Typography>} </Typography>

          })}
        </Typography>

        <Popover
          id="assign"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component={'span'} className={classes.typography}>
            <List component="nav">
              {this.state.userId == note.author._id ? (
              <div>
                {users.length > 0 ? 
              <Typography component={'span'}>{users.map((user, i) => {
                return <Typography>
                  {this.state.userId == user._id ? (<ListItem button onClick={() => this.assign(user._id)}>
                    <ListItemText primary={'Assign to me'} />
                  </ListItem>) : <ListItem button onClick={() => this.assign(user._id)}>
                      <ListItemText primary={user.fullName} />
                    </ListItem>}
                </Typography>
              })}
              </Typography>
              : <Typography>
                  <ListItemText primary={'No Other Users!!'} />
                </Typography>
              }
              </div> 
              ) : null}

            </List></Typography>
        </Popover>
      </div>
    );
  }
}

Assign.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Assign);
